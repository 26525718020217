<template>
  <div
    class="ringbox"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      class="ring_body"
      :id="this.id"
      :style="{ width: width - 10 + 'px', height: height - 10 + 'px' }"
    ></div>
    <div class="tipbox">
      <div class="con">{{ title }}</div>
      <div class="help">
        <el-tooltip placement="bottom" effect="light" popper-class="pop_tipbox">
          <div slot="content">
            <p>
              “信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。
              它分为低，中和高三个级别<br />
            </p>
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
    <div v-if="jltip > 3">
      <div class="trend up" v-if="jldata.exceptPercent > 0">
        <p><i class="el-icon-top top"></i></p>
        <p class="content">可能上涨</p>
        <p class="percent">+{{ jldata.exceptPercent }}%</p>
      </div>
      <div class="trend down" v-else-if="jldata.exceptPercent < 0">
        <p><i class="el-icon-bottom top"></i></p>
        <p class="content">可能下跌</p>
        <p class="percent">-{{ trendData }}%</p>
      </div>
      <div class="trend up" v-else>
        <p><i class="el-icon-top top"></i></p>
        <p class="content">可能上涨</p>
        <p class="percent">{{ jldata.exceptPercent }}%</p>
      </div>
    </div>
  </div>
</template>
<script>
import chartTheme from "@/utils/chartTheme.js";

// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
import tools from "@/utils/tools.js";
export default {
  name: "jlring",
  data() {
    return {
      // 刻度颜色
      axisColor: "#797979",
      // 分割线颜色
      splitLineColor: "#dcdcdc",
      chart: null,
      chartData: Array,
      loading: true,
      tips: "",
      showTip: false,
      color1:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#282828"
          : "#f39c02",
      color2:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#282828"
          : "#f39c02",
      color3:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#fff"
          : "#222"
    };
  },
  mixins: [tools],
  props: {
    // 图表区域高度
    title: {
      type: String,
      default: "饼状图"
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    // 图表形状颜色, ecahrts依次选择颜色渲染
    color: {
      type: Array,
      default: function() {
        return chartTheme.ringColor;
      }
    },
    // 背景颜色
    bgColor: {
      type: String,
      default: "#fff"
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },

  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },

  watch: {
    jldata: function(newVal) {
      // if (newVal.length > 0) {
      this.filterData(newVal);
      // } else {
      //   this.emptyData();
      // }
    }
  },
  mounted() {
    console.log("ringdata", this.jldata);
    // if (this.jldata.length > 0) {
    this.filterData(this.jldata);
    // } else {
    //   this.emptyData();
    // }
  },
  methods: {
    toggleTips() {
      console.log(1);
    },
    emptyData() {
      this.chartData = [
        {
          name: "?",
          value: 1,
          itemStyle: {
            normal: {
              color: {
                // type: "linear",
                // colorStops: [
                //   // !! 在此添加渐变过程色 !!
                //   { offset: 0, color: "#BC1737" },
                //   { offset: 1, color: "#FB9F06" }
                // ]
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: this.color1 // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: this.color1 // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
              // shadowColor: "rgba(34,192,245,0.8)",
              // shadowBlur: 10
            }
          }
        }
      ];
      this.loading = false;
      this.renderChart();
    },
    filterhighData(param) {
      if (param == "noNews") {
        return "低";
      } else if (param == "belowAverage") {
        return "中";
      } else if (param == "Average") {
        return "高";
      } else if (param == "aboveAverage") {
        return "高";
      } else if (param == "buzzing") {
        return "高";
      }
    },
    filterData(data) {
      let _this = this;
      switch (_this.title) {
        case "信心指数":
          _this.chartData = [
            {
              name: _this.filterhighData(data.buzzLevel),
              value: 100,
              itemStyle: {
                normal: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#E83E8A" // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#981A3C" // 100% 处的颜色
                      }
                    ],
                    global: false // 缺省为 false
                  }
                }
              }
            }
          ];
          break;
      }
      _this.loading = false;
      _this.renderChart();
    },
    renderChart: function() {
      let _this = this;
      if (this.chart) {
        this.chart.dispose();
      }
      // 初始化echart
      this.chart = echarts.init(document.getElementById(this.id));
      // 自定义eChart样式 官方配置指南(http://echarts.baidu.com/option.html#yAxis.splitLine.lineStyle.color)
      this.chart.setOption({
        title: {
          // text: this.title,
          // textStyle: {
          //   fontSize: 16,
          //   color: "#b4b4b4"
          // },
          // // top: 2,
          // left: 10
        },
        grid: {
          left: 30,
          right: 15
        },
        color: this.color,
        tooltip: {
          formatter: function(params, ticket, callback) {
            // console.log(params);
            //x轴名称
            // var name = params.name;
            //值
            // var value = params.value;
            // return name;
          }
        },
        series: {
          type: "pie",
          radius: ["45%", "65%"],
          avoidLabelOverlap: false,
          hoverOffset: 4,
          label: {
            //  饼图图形上的文本标签
            normal: {
              // normal 是图形在默认状态下的样式
              // text: "222",
              show: false,
              position: "center",
              color: this.color3,
              fontSize: 18,
              fontWeight: "bold"
              // formatter: `{c}`
              // formatter: function() {
              //   return { c };
              // } // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
            },

            emphasis: {
              show: true,
              formatter: function(params) {
                // let name = null,
                // arr = null,
                // str = null;
                // arr = name.split(":");
                // str = arr[0];
                // return str;
              },
              textStyle: {
                fontSize: "15",
                fontWeight: "bold"
              }
            }
          },
          data: this.chartData
        }
      });

      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0
      });
      this.chart.on("mouseover", v => {
        if (v.dataIndex != 0) {
          _this.chart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: 0
          });
        }
      });
      this.chart.on("mouseout", v => {
        this.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: 0
        });
      });
    }
  }
};
</script>
<style lang="scss">
.ringbox {
  position: relative;
  // background: black;
  // background: #191919;
  @include themeBg1();

  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .tipbox {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 6px;

    // color: #b4b4b4;

    z-index: 999;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    @include fontColor();
    .help {
      margin-left: 20px;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        @include fontColor2();
        @include themeBg2();
        // background: #3a3a3a;
      }
      .pop_tipbox {
        border: 1px solid #5a5a5a !important;
        // color: #fff !important;
        // background: #303030 !important;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
}
</style>
