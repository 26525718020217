<template>
  <div
    class="jlmainrank"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="rank" v-if="jldata.length > 0">
      <ul class="rankitem">
        <li
          v-for="(item, index) in jldata"
          :key="item"
          :index="index"
          @click="selectCompay(item)"
        >
          <div class="title">
            <p class="name" :title="item.name">
              {{ item.name }}
            </p>
            <p class="star_box">
              <i
                class="el-icon-star-off star greystar"
                @click="changeSelf(1, item)"
                v-if="item.watch_list == 0"
              ></i>
              <i
                class="el-icon-star-on star staron"
                v-else-if="item.watch_list == 1"
                @click="changeSelf(0, item)"
              ></i>
            </p>
          </div>
          <div class="progress">
            <!-- 三种类型的排行 根据不同的值判断 -->
            <div
              v-if="type == 1"
              class="news"
              :style="{
                width: (item.all_count / jldata[0].all_count) * 100 + '%'
              }"
            ></div>
            <div
              v-else-if="type == 2"
              class="news"
              :class="item.sentimentLevel"
              :style="{
                width: (item.all_count / jldata[0].all_count) * 100 + '%'
              }"
            ></div>
            <div
              v-else-if="type == 3"
              class="news"
              :class="{
                less: item.watch < 0,
                equal: item.watch == 0 || item.watch == null,
                more: item.watch > 0
              }"
              :style="{
                width: (item.all_count / jldata[0].all_count) * 100 + '%'
              }"
            ></div>
            <!-- <div
              class="blog"
              :class="{ blog_mind: type == 2, blog_trend: type == 3 }"
              :style="{
                width: (item.blog_count / jldata[0].all_count) * 100 + '%'
              }"
            ></div> -->
          </div>
          <div class="total">{{ item.all_count }}</div>
        </li>
      </ul>
    </div>
    <div v-else style="color:#fff;">暂无数据</div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import tools from "@/utils/tools";
import { addSelfStock, delSelfStock } from "@/request/config";

export default {
  name: "jlmainrank",
  data() {
    return {
      loading: true,
      rankData: ""
    };
  },
  mixins: [tools],
  props: {
    // jldata: {
    //   type: Array
    // },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 200
    },
    title: {
      type: String,
      default: "排名"
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    jltip: {
      type: Number,
      defalut: 0
    },
    type: {
      type: Number,
      defalut: 0
    }
  },
  watch: {
    jldata: function(newVal) {
      if (newVal.length > 0) {
        this.rankData = this.filterData(newVal.topics);
      }
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  mounted() {
    this.loading = false;
    // console.log(this.jldata);
    // this.rankData = this.filterData(this.jldata);
  },
  methods: {
    ...mapActions("home", ["setCompany"]),

    filterData(e) {
      let tempData = e;
      for (let i = 0; i < tempData.length; i++) {
        if (i == 0) {
          tempData[i].color = "#FF7373";
        } else {
          tempData[i].color = "#E99863";
        }
      }
      return tempData;
    },
    formatprogress(percentage) {
      // return percentage === 100 ? "满" : `${percentage}%`;
      // for(let i in this.rankData)
      let _this = this;

      for (let i = 0; i < _this.rankData.length; i++) {
        if (_this.rankData[i].percent == percentage) {
          return `${_this.rankData[i].value}`;
        }
      }
    },
    changeSelf(e, item) {
      let localUserId = this.$cookie.get("userId");
      let localUserName = this.$cookie.get("userName");

      if (e) {
        addSelfStock({
          username: localUserName,
          userId: localUserId,
          entityId: item.entity_id
        }).then(res => {
          if (res.data.code) {
            this.$message({
              showClose: true,
              message: "添加成功",
              type: "success"
            });
            this.$emit("dataChange", true);
          }
        });
      } else {
        delSelfStock({
          username: localUserName,
          userId: localUserId,
          entityId: item.entity_id
        }).then(res => {
          console.log(res.data.code);
          if (res.data.code) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success"
            });
            this.$emit("dataChange", true);
          }
        });
      }
    },
    selectCompay(e) {
      this.setCompany(e);
      _this.$emit("userclick", e);
    }
  }
};
</script>
<style lang="scss">
.jlmainrank {
  width: 60%;
  margin: 0 auto;
  .rank {
    display: flex;
    ul {
      // width: 1000px;
      // height: 1000px;
      height: 800px;
      margin: 0 auto;
      overflow: auto;
      li {
        display: flex;
        align-items: center;
        height: 40px;
        .title {
          width: 340px;
          font-size: 22px;
          // color: #fff;
          text-align: right;
          margin-right: 40px;
          display: flex;
          justify-content: space-between;
          @include fontColor();

          .name {
            text-align: left;
            width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .star_box {
            .star {
              margin-left: 20px;
              font-size: 20px;
              // color: red;
            }
            .greystar {
              padding-right: 4px;
            }
            .staron {
              font-size: 26px;
              color: #ff7373;
            }
          }
        }
        .progress {
          display: flex;
          align-items: center;
          width: 400px;
          height: 100%;
          .news {
            // background: #3d8ae7;
            background: linear-gradient(to right, #f39c02, #e42349);
            height: 10px;
          }
          .news.very-negative {
            background: #25ff83;
          }
          .news.negative {
            background: #00d25b;
          }
          .news.neutral {
            background: #f39c00;
          }
          .news.positive {
            background: #fd9297;
          }
          .news.very-positive {
            background: #fc2e37;
          }
          .news.less {
            background: #0097fe;
          }
          .news.equal {
            background: #929292;
          }
          .news.more {
            background: #e42349;
          }

          // .mind {
          //   background: red;
          // }
          // .trend {
          //   background: chartreuse;
          // } .blog {
          // background: #fb9f06;
          // background: linear-gradient(to right, #f39c02, #e42349);

          // height: 10px;
          // }
          .blog_mind {
            // background: bisque;
          }
          .blog_trend {
            // background: burlywood;
          }
        }
        .total {
          margin-left: 40px;
          font-size: 22px;
          // color: #fff;
          @include fontColor();
        }
      }
    }
    .rankitem {
      padding-right: 20px;
      li:hover {
        // height: 50px;
        background: cornflowerblue;
        cursor: pointer;
      }
    }

    .rankitem::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    .rankitem::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
      background: #535353;
    }
    .rankitem::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
      border-radius: 10px;
      // background: #ededed;
      background: black;
    }
  }
}
</style>
