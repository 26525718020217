<template>
  <div
    class="timeseries"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="titlebox">
      <div class="title">{{ title }}</div>
      <div class="notice">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content">
            <p v-if="jltip == 1">
              “历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。
            </p>
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- d3 chart -->
    <div
      header
      class="legend"
      style="width: auto; margin-top: 0;"
      v-if="legendInHeader"
    >
      <div class="legend1">
        <div
          class="dot"
          :style="{
            'background-image':
              'radial-gradient(' + color1 + ', ' + color1 + ')'
          }"
          style="border-radius: 0;"
        ></div>
        <span
          class="descr"
          style="font-size: 0.8rem; text-transform: uppercase;"
          >{{ label1 }}</span
        >
      </div>
      <div
        class="legend2"
        :class="enableArea2 || enableLine2 ? '' : 'hide'"
        :style="{ 'padding-right': marginRight + 'px' }"
        style="text-align: right;"
      >
        <div
          class="dot"
          :style="{
            'background-image':
              'radial-gradient(' + color4 + ', ' + color3 + ')'
          }"
          style="border-radius: 0;"
        ></div>
        <span
          class="descr"
          style="font-size: 0.8rem; text-transform: uppercase;"
          >{{ label2 }}</span
        >
      </div>
    </div>
    <div class="tcl-chart-container">
      <span style="flex : 1 1 auto"></span>
      <svg
        id="tcl-chart-widget"
        class="timeseries_linesvg"
        :style="{ width: width + 'px', height: 300 + 'px' }"
      >
        <defs>
          <linearGradient
            :id="'gradient1' + uniqueId"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="25%" :stop-color="color1" />
            <stop offset="100%" :stop-color="color2" />
          </linearGradient>
          <linearGradient
            :id="'gradient2' + uniqueId"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="25%" :stop-color="color3" />
            <stop offset="100%" :stop-color="color4" />
          </linearGradient>
        </defs>
      </svg>
      <div class="legend" v-if="!legendInHeader">
        <div class="legend1" :style="{ 'padding-left': marginLeft + 'px' }">
          <div
            class="dot"
            :style="{
              'background-image':
                'radial-gradient(' + color2 + ', ' + color1 + ')'
            }"
          ></div>
          <span class="descr">{{ label1 }}</span>
        </div>
        <div
          class="legend2"
          :class="enableArea2 || enableLine2 ? '' : 'hide'"
          :style="{ 'padding-right': marginRight + 'px' }"
          style="text-align: right;"
        >
          <span style="flex : 1 1 auto;"></span>
          <span class="descr">{{ label2 }}</span>
          <div
            class="dot"
            :style="{
              'background-image':
                'radial-gradient(' + color4 + ', ' + color3 + ')'
            }"
          ></div>
        </div>
      </div>
      <span style="flex : 1 1 auto"></span>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { gsap, MorphSVGPlugin, Linear, Elastic, Power1 } from "gsap/all";
import { ord1, ord2, abs1Array } from "./linedata";
import tools from "@/utils/tools";

export default {
  name: "gsap_timeseries",
  data() {
    return {
      loading: true,
      percentage: 0,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ],
      tips: "",
      color1: "red",
      // color2: "lightgrey",
      color2:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "lightgrey"
          : "#222",
      // xColor: "pink",
      xColor:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "pink"
          : "#222",
      // color3: "rgb(168, 176, 183)",
      // color4: "rgb(168, 176, 183)",
      color4:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "rgb(168, 176, 183)"
          : "#222",

      // xFormat: "%d/%m",
      xFormat: "%m/%d",
      strokeColor: "yellow",
      areaOpacity: 0.9,
      strokeOpacity: 0.7,
      viewBoxX: 600,
      viewBoxY: 300,
      svgTSRef: null,
      svgRef: d3.select(".timeseries_linesvg"),
      // label1: "Label1",
      // label2: "Label2",
      enableArea1: true,
      enableArea2: true,
      area2Opacity: 0.6,
      enableLine1: false,
      enableLine2: false,
      linesWidth: 1,
      legendInHeader: true,
      ord1Array: ord1,
      ord1MaxMin: null,
      ord2MaxMin: null,
      ord2Array: ord2,
      transitionSpeed: 500,
      firstAnimate: true,
      repaint: null,
      preparedData: null,
      timeseriesEnabled: true,
      timeSeriesHeight: 75,
      marginSideBase: 30,
      marginLeft: 30,
      marginRight: 30,

      chartHeight: null,

      chart: null,
      timeseries: null,

      minX: null,
      maxX: null,
      xScale: null,
      xScaleTimeSeries: null,
      yScaleTimeseries: null,
      yScaleLeft: null,
      yScaleLeftDiff: null,
      yScaleRight: null,
      leftAxis: null,
      rightAxis: null,
      leftArea1: null,
      leftArea2: null,
      leftArea3: null,
      leftArea4: null,
      rightArea: null,
      leftLine: null,
      rightLine: null,
      areaTimeseries: null,
      brush: null,
      zoom: null,

      holdLoop: false,
      anims: [],
      //line chart above
      yScale: null,
      leftArea: null,
      uniqueId: null,
      //
      menuItems1: [
        {
          title: "24h",
          func: () => {
            return "24h";
          }
        },
        {
          title: "7days",
          func: () => {
            return "7d";
          }
        },
        {
          title: "30days",
          func: () => {
            return "30d";
          }
        },
        {
          title: "90days",
          func: () => {
            return "90d";
          }
        }
      ],
      minMax: [0, 20],
      currentTimeFrame: "24h",
      options: null,

      speedoMin: 0,
      speedoMax: 100,
      item1Angle: 40,

      gridInitialized: false,
      ord1: ord1,
      ord2: ord2,
      abs1Array: abs1Array,
      // bubblesDatas: bubblesDatas,
      animatedWeatherDimension: null,
      chartY: 200,
      percent1: 0.6,
      percent2: 0.4,
      goZero: false,
      percent11: 0.2,
      percent22: 0.3,
      percent33: 0.5,
      goZero2: false,
      confidenceCurrentWind: 0,

      weatherIndex2: 0,

      avPosition: 1,
      avPercent: 0.3899,
      // articleSample: articleSample,

      menuItems: [
        {
          title: "24h",
          func: () => {
            return "24h";
          }
        },
        {
          title: "7days",
          func: () => {
            return "7d";
          }
        },
        {
          title: "30days",
          func: () => {
            return "30d";
          }
        },
        {
          title: "90days",
          func: () => {
            return "90d";
          }
        }
      ]
    };
  },
  mixins: [tools],
  props: {
    label1: {
      type: String,
      default: "情绪值"
    },
    label2: {
      type: String,
      default: "市场价格"
    },
    width: {
      type: Number,
      default: 600
    },

    height: {
      type: Number,
      default: 300
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    title: {
      type: String,
      default: "新闻热度"
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  watch: {
    jldata: function(newVal) {
      this.filterData(newVal);
    }
  },
  created() {
    gsap.registerPlugin(MorphSVGPlugin);
    this.uniqueId = Math.floor(Math.random() * Math.floor(4000));
  },
  mounted() {
    this.loading = false;
    this.filterData(this.jldata);
    // this.updateViewboxWidth();
    // this.prepareData();
    // this.initDefinitions();
    // this.initChart();
  },
  destroyed() {
    this.onChanges.subscribe(data => {
      if (
        (data.ord1Array && data.ord1Array.currentValue) ||
        (data.ord2Array && data.ord2Array.currentValue) ||
        (data.abs1Array && data.abs1Array.currentValue)
      ) {
        this.paintChart();
      }

      if (data.repaint && data.repaint.currentValue) {
        this.paintChart();
      }
    });
  },
  methods: {
    filterData(e) {
      let tempOrd1 = [],
        tempOrd2 = [],
        tempAbs = [];

      e.map((item, index) => {
        tempOrd1.push(item.zscore * 100);
        tempOrd2.push(item.close);
        tempAbs.push(item.timestamp);
      });
      this.ord1Array = tempOrd1;
      this.ord2Array = tempOrd2;
      this.abs1Array = tempAbs;

      this.prepareData();
      this.initDefinitions();
      this.initChart();
    },
    getValueEmit(value) {
      this.timeFrameEmitter.emit(value);
    },
    updateViewboxWidth() {
      if (
        this.svgRef &&
        this.svgRef.nativeElement &&
        this.svgRef.nativeElement.parentNode
      ) {
        this.viewBoxX = this.svgRef.nativeElement.parentNode.clientWidth;
      }
    },
    ngOnChanges(changes) {
      this.firstAnimate = true;
      this.onChanges.next(changes);
    },
    prepareData() {
      this.preparedData = [];

      this.xScale = null;
      this.xScaleTimeSeries = null;
      this.yScaleTimeseries = null;
      this.yScaleLeft = null;
      this.yScaleLeftDiff = null;
      this.yScaleRight = null;

      this.abs1Array.forEach((element, i) => {
        this.preparedData.push({
          ord1: this.ord1Array[i],
          ord2: this.ord2Array[i],
          abs1: this.abs1Array[i]
        });
      });
    },
    initDefinitions() {
      this.chartHeight = this.viewBoxY - this.timeSeriesHeight;

      let ord1Min, ord1Max;

      if (this.ord1MaxMin) {
        ord1Min = this.ord1MaxMin[0];
        ord1Max = this.ord1MaxMin[1];
      } else {
        ord1Min = d3.min(this.ord1Array, function(d) {
          return +d;
        });
        ord1Max = d3.max(this.ord1Array, function(d) {
          return +d;
        });
      }

      let ord2Min, ord2Max;

      if (this.ord2MaxMin) {
        ord2Min = this.ord2MaxMin[0];
        ord2Max = this.ord2MaxMin[1];
      } else {
        ord2Min = d3.min(this.ord2Array, function(d) {
          return +d;
        });
        ord2Max = d3.max(this.ord2Array, function(d) {
          return +d;
        });
      }
      this.yScaleTimeseries = d3
        .scaleLinear()
        .domain([ord2Min, ord2Max])
        .range([this.timeSeriesHeight - 30, 0]);

      // Defining left Y scale
      this.yScaleLeft = d3
        .scaleLinear()
        .domain([ord1Min, ord1Max])
        .range([this.chartHeight, 0]);

      // Defining left Y scale
      this.yScaleLeftDiff = d3
        .scaleLinear()
        .domain([ord1Min - ord1Max, ord1Max - ord1Min])
        .range([this.chartHeight / 16, -(this.chartHeight / 16)]);

      // Defining right Y scale
      this.yScaleRight = d3
        .scaleLinear()
        .domain([
          d3.min(this.ord2Array, function(d) {
            return +d;
          }),
          ord2Max
        ])
        .range([this.chartHeight, 0]);
      this.marginLeft = this.marginSideBase + ("" + ord1Max).length * 4;
      this.marginRight = this.marginSideBase + ("" + ord2Max).length * 4;

      // Defining bottom X scale
      let xRange = d3.extent(this.preparedData, d => {
        return new Date(d["abs1"]);
      });
      // console.log(xRange);
      this.minX = xRange[0];
      this.maxX = xRange[1];
      this.xScale = d3
        .scaleTime()
        .range([this.marginLeft, this.viewBoxX - this.marginRight])
        .domain(xRange);
      // .nice();

      this.xScaleTimeSeries = d3
        .scaleTime()
        .range([this.marginLeft, this.viewBoxX - this.marginRight])
        .domain([this.minX, this.maxX]);
      // .nice();

      // Drawing functions
      if (this.enableLine1 || this.enableArea1) {
        this.leftAxis = d3.axisLeft(this.yScaleLeft).ticks(10);
      }
      if (
        this.ord2Array &&
        this.ord2Array.length > 0 &&
        (this.enableLine2 || this.enableArea2)
      ) {
        this.rightAxis = d3.axisRight(this.yScaleRight).ticks(10);
      }
      this.leftLine = d3
        .line()
        .x((d, i) => {
          return this.xScale(new Date(d["abs1"]));
        })
        .y(d => {
          return this.yScaleLeft(d["ord1"]);
        })
        .curve(d3.curveBasis);

      this.leftArea1 = d3
        .area()
        .x((d, i, arr) => {
          const r = i !== 0 && i !== arr.length - 1 ? this.randomize() * 2 : 0;
          return this.xScale(new Date(d["abs1"])) + r;
        })
        .y0((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 2)
              : 1;
          return this.yScaleLeft(d["ord1"]) + diff;
        })
        .y1((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 2)
              : 1;
          return this.yScaleLeft(d["ord1"]) - diff;
        })
        .curve(d3.curveBasis);

      this.leftArea2 = d3
        .area()
        .x((d, i, arr) => {
          const r = i !== 0 && i !== arr.length - 1 ? this.randomize() * 2 : 0;
          return this.xScale(new Date(d["abs1"])) + r;
        })
        .y0((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 3)
              : 1;
          return this.yScaleLeft(d["ord1"]) + diff;
        })
        .y1((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 3)
              : 1;
          return this.yScaleLeft(d["ord1"]) - diff;
        })
        .curve(d3.curveBasis);

      this.leftArea3 = d3
        .area()
        .x((d, i, arr) => {
          const r = i !== 0 && i !== arr.length - 1 ? this.randomize() * 2 : 0;
          return this.xScale(new Date(d["abs1"])) + r;
        })
        .y0((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 4)
              : 1;
          return this.yScaleLeft(d["ord1"]) + diff;
        })
        .y1((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 4)
              : 1;
          return this.yScaleLeft(d["ord1"]) - diff;
        })
        .curve(d3.curveBasis);

      this.leftArea4 = d3
        .area()
        .x((d, i, arr) => {
          const r = i !== 0 && i !== arr.length - 1 ? this.randomize() * 2 : 0;
          return this.xScale(new Date(d["abs1"])) + r;
        })
        .y0((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 5)
              : 0;
          return this.yScaleLeft(d["ord1"]) + diff;
        })
        .y1((d, i, arr) => {
          const diff =
            i !== 0 && i !== arr.length - 1
              ? Math.abs(this.getDifferenceTop(arr, i) * 5)
              : 0;
          return this.yScaleLeft(d["ord1"]) - diff;
        })
        .curve(d3.curveBasis);

      this.rightArea = d3
        .area()
        .x((d, i) => {
          return this.xScale(new Date(d["abs1"]));
        })
        .y0(this.chartHeight)
        .y1(d => {
          return this.yScaleRight(d["ord2"]);
        });

      this.areaTimeseries = d3
        .area()
        .x((d, i) => {
          return this.xScaleTimeSeries(new Date(d["abs1"]));
        })
        .y0(this.timeSeriesHeight - 30)
        .y1(d => {
          return this.yScaleTimeseries(d["ord2"]);
        });

      this.rightLine = d3
        .line()
        .x((d, i) => {
          return this.xScale(new Date(d["abs1"]));
        })
        .y(d => {
          return this.yScaleRight(d["ord2"]);
        });

      this.brush = d3
        .brushX()
        .extent([
          [this.marginLeft, 0],
          [this.viewBoxX - this.marginRight, this.timeSeriesHeight - 30]
        ])
        .on("brush", this.brushed);

      this.zoom = d3
        .zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([
          [0, 0],
          [this.viewBoxX, this.chartHeight]
        ])
        .extent([
          [0, 0],
          [this.viewBoxX, this.chartHeight]
        ])
        .on("zoom", () => {
          return this.zoomed();
        });
    },
    brushed() {
      if (this.xScaleTimeSeries) {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom")
          return; // ignore brush-by-zoom
        var s = d3.event.selection || this.xScaleTimeSeries.range();
        this.xScale.domain(
          s.map(this.xScaleTimeSeries.invert, this.xScaleTimeSeries)
        );
        this.drawAreaAndLines();
        // this.focus.select(".axis--x").call(xAxis);
        this.svgTSRef
          .select(".zoom")
          .call(
            this.zoom.transform,
            d3.zoomIdentity
              .scale(this.viewBoxX / (s[1] - s[0]))
              .translate(-s[0], 0)
          );
      }
    },
    zoomed() {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
      var t = d3.event.transform;
      this.xScale.domain(t.rescaleX(this.xScaleTimeSeries).domain());

      this.drawAreaAndLines(); // this.chart.select(".axis--x").call(xAxis);
      this.timeseries
        .select(".brush")
        .call(this.brush.move, this.xScale.range().map(t.invertX, t));
    },
    drawAreaAndLines() {
      this.killLoops();

      let tSpeed = 0;
      if (this.firstAnimate) {
        tSpeed = this.transitionSpeed;
      }
      if (this.ord1Array && this.ord1Array.length > 0 && this.enableArea1) {
        this.chart
          .select("#ord1PathArea1")
          .transition()
          .duration(tSpeed)
          .attr("d", this.leftArea1);
        this.chart
          .select("#ord1PathArea2")
          .transition()
          .duration(tSpeed)
          .attr("d", this.leftArea2);
        this.chart
          .select("#ord1PathArea3")
          .transition()
          .duration(tSpeed)
          .attr("d", this.leftArea3);
        this.chart
          .select("#ord1PathArea4")
          .transition()
          .duration(tSpeed)
          .attr("d", this.leftArea4);
      }
      if (this.ord1Array && this.ord1Array.length > 0 && this.enableLine1) {
        this.chart
          .select("#ord1PathLine")
          .transition()
          .duration(tSpeed)
          .attr("d", this.leftLine);
      }

      if (this.ord2Array && this.ord2Array.length > 0 && this.enableArea2) {
        this.chart
          .select("#ord2PathArea")
          .transition()
          .duration(tSpeed)
          .attr("d", this.rightArea);
      }

      if (this.ord2Array && this.ord2Array.length > 0 && this.enableLine2) {
        this.chart
          .select("#ord2PathLine")
          .transition()
          .duration(tSpeed)
          .attr("d", this.rightLine);
      }

      this.firstAnimate = false;

      setTimeout(() => {
        this.animLoops();
      }, tSpeed);
    },
    killLoops() {
      this.holdLoop = true;
      if (this.chart && !this.chart.select("#ord1PathArea1").empty())
        gsap.killTweensOf("#ord1PathArea1");
      if (this.chart && !this.chart.select("#ord1PathArea2").empty())
        gsap.killTweensOf("#ord1PathArea2");
      if (this.chart && !this.chart.select("#ord1PathArea3").empty())
        gsap.killTweensOf("#ord1PathArea3");
      if (this.chart && !this.chart.select("#ord1PathArea4").empty())
        gsap.killTweensOf("#ord1PathArea4");
    },
    animLoop1() {
      let selected = this.chart.select("#ord1PathArea1");
      let data = selected.data()[0];

      if (selected && selected.node() && data) {
        let p = this.leftArea1(selected.data()[0]);
        gsap.to(selected.node(), {
          morphSVG: p,
          duration: 1.5,
          ease: Power1.easeInOut,
          onComplete: () => {
            if (!this.holdLoop) this.animLoop1();
          }
        });
      }
    },
    animLoop2() {
      let selected = this.chart.select("#ord1PathArea2");
      let data = selected.data()[0];

      if (selected && selected.node() && data) {
        let p = this.leftArea2(selected.data()[0]);
        gsap.to(selected.node(), {
          morphSVG: p,
          duration: 2,
          ease: Power1.easeInOut,
          onComplete: () => {
            if (!this.holdLoop) this.animLoop2();
          }
        });
      }
    },
    animLoop3() {
      let selected = this.chart.select("#ord1PathArea3");
      let data = selected.data()[0];

      if (selected && selected.node() && data) {
        let p = this.leftArea3(selected.data()[0]);
        gsap.to(selected.node(), {
          morphSVG: p,
          duration: 2.5,
          ease: Power1.easeInOut,
          onComplete: () => {
            if (!this.holdLoop) this.animLoop3();
          }
        });
      }
    },
    animLoop4() {
      let selected = this.chart.select("#ord1PathArea4");
      let data = selected.data()[0];

      if (selected && selected.node() && data) {
        let p = this.leftArea4(selected.data()[0]);
        gsap.to(selected.node(), {
          morphSVG: p,
          duration: 3,
          ease: Power1.easeInOut,
          onComplete: () => {
            if (!this.holdLoop) this.animLoop4();
          }
        });
      }
    },
    animLoops() {
      this.holdLoop = false;
      this.animLoop1();
      this.animLoop2();
      this.animLoop3();
      this.animLoop4();
    },
    randomize() {
      let num = Math.floor(Math.random() * (this.chartHeight / 30)) / 2;
      return (num *= Math.floor(Math.random() * 2) == 1 ? 1 : -1);
    },
    getDifferenceTop(array, index) {
      if (index > 0) {
        let val =
          this.yScaleLeftDiff(array[index - 1].ord1 - array[index].ord1) +
          this.randomize();
        return val < 0 ? val : 0;
      } else {
        return 0;
      }
    },
    getDifferenceBottom(array, index) {
      if (index > 0) {
        let val =
          this.yScaleLeftDiff(array[index - 1].ord1 - array[index].ord1) +
          this.randomize();
        return val > 0 ? val : 0;
      } else {
        return 0;
      }
    },
    initChart() {
      this.svgTSRef = d3
        .select(".timeseries_linesvg")
        .attr("viewBox", "0 0 " + this.viewBoxX + " " + this.viewBoxY);
      this.killLoops();
      // Cleaning the svg canvas for a proper redraw
      this.svgTSRef
        .select("#mainG" + this.uniqueId)
        .selectAll("g")
        .remove();

      this.svgTSRef
        .select("#mainG" + this.uniqueId)
        .selectAll("rect")
        .remove();

      // Cleaning the timeseries canvas for a proper redraw
      this.svgTSRef
        .select("#timeseries" + this.uniqueId)
        .selectAll("g")
        .remove();
      this.svgTSRef.selectAll("rect").remove();

      this.svgTSRef.select("clipPath").remove();

      this.svgTSRef
        .select("defs")
        .append("clipPath")
        .attr("id", "clipChart" + this.uniqueId)
        .append("rect")
        .attr("transform", "translate(" + this.marginLeft + ", 0)")
        .attr("width", this.viewBoxX - this.marginLeft - this.marginRight)
        .attr("height", this.chartHeight + 20);

      this.svgTSRef
        .select("#timeseries" + this.uniqueId)
        .selectAll("rect")
        .remove();

      // Setting svg size based on html element's size

      if (d3.select("#mainG" + this.uniqueId).empty()) {
        this.chart = this.svgTSRef
          .style("overflow", "visible")
          .append("g")
          .attr("id", "mainG" + this.uniqueId)
          .style("pointer-events", "all")
          .style("width", "100%")
          .attr("transform", "translate(0, 0)");
      }

      if (d3.select("#timeseries" + this.uniqueId).empty()) {
        this.timeseries = this.svgTSRef
          .style("overflow", "visible")
          .append("g")
          .attr("id", "timeseries" + this.uniqueId)
          .style("pointer-events", "all")
          .attr("transform", "translate(0, " + (this.chartHeight + 10) + ")");
      }
      // let rect = this.svgTSRef
      //   .append("svg:rect")
      //   .attr("class", "pane zoom")
      //   .attr("width", this.viewBoxX - this.marginRight)
      //   .attr("height", this.chartHeight)
      //   .attr("transform", "translate(" + this.marginSideBase + "," + 0 + ")")
      //   .call(this.zoom);

      // if (this.ord2Array && this.ord2Array.length > 0 && (this.enableArea2 || this.enableLine2)) {
      this.svgTSRef.select(".xAxis").remove();
      this.svgTSRef
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(" + 0 + "," + (this.viewBoxY - 20) + ")")
        .style("font-size", "10px")
        .call(
          d3
            .axisBottom(this.xScaleTimeSeries)
            // .tickValues([6, 7, 8, 9, 10])
            .ticks(7)
            // .tickPadding(15)
            // .tickSize(15)
            .tickFormat(d3.timeFormat(this.xFormat))
        )
        // .selectAll(".tick")
        // .style("width", "400px")
        .selectAll(".tick text")
        .style("fill", this.xColor);
      // }
      let customLeftAxis;

      if (
        this.ord1Array &&
        this.ord1Array.length > 0 &&
        (this.enableLine1 || this.enableArea1)
      ) {
        customLeftAxis = g => {
          g.call(this.leftAxis);
          g.select(".domain").remove();
        };
      }
      let customRightAxis;
      if (
        this.ord2Array &&
        this.ord2Array.length > 0 &&
        (this.enableLine2 || this.enableArea2)
      ) {
        customRightAxis = g => {
          g.call(this.rightAxis);
          g.select(".domain").remove();
        };
      }

      // Building bottom axis
      this.chart
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(" + 0 + "," + this.chartHeight + ")")
        .style("font-size", "10px")
        // .selectAll(".tick")
        // .style("width", "50px;")
        .selectAll(".tick text")
        .style("fill", this.xColor);

      if (
        this.ord1Array &&
        this.ord1Array.length > 0 &&
        (this.enableLine1 || this.enableArea1)
      ) {
        // Building left axis
        const yLeftAxis = this.chart
          .append("g")
          .attr("class", "yLeftAxis")
          .attr("transform", "translate(" + this.marginLeft + "," + 0 + ")");

        yLeftAxis
          .append("g")
          .attr("transform", "translate(" + 0 + "," + 0 + ")")
          .call(customLeftAxis)
          .attr("text-anchor", "end")
          .style("font-size", "10px")
          .selectAll(".tick text")
          .style("font-weight", "bold")
          .style("fill", this.color2);
      }

      if (
        this.ord2Array &&
        this.ord2Array.length > 0 &&
        (this.enableLine2 || this.enableArea2)
      ) {
        // Building right axis
        const yRightAxis = this.chart
          .append("g")
          .attr("class", "yRightAxis")
          .attr(
            "transform",
            "translate(" + (this.viewBoxX - this.marginRight) + "," + 0 + ")"
          );

        yRightAxis
          .append("g")
          .call(customRightAxis)
          .style("font-size", "10px")
          .style("font-weight", "bold")
          .selectAll(".tick text")
          .style("fill", this.color4);
      }

      if (this.ord2Array && this.ord2Array.length > 0 && this.enableArea2) {
        if (!d3.select("#ord2PathArea").empty()) {
          d3.select("#ord2PathArea")
            .attr("class", "rightArea")
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.rightArea);
        } else {
          this.chart
            .append("path")
            .attr("id", "ord2PathArea")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.rightArea)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("transform-origin", "0px " + this.chartHeight + "px")
            .style("fill", " url(#gradient2" + this.uniqueId + ")")
            .style("opacity", this.area2Opacity);
        }

        if (!d3.select("#ordPathTimeseries").empty()) {
          d3.select("#ordPathTimeseries")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.areaTimeseries);
        } else {
          this.timeseries
            .append("path")
            .attr("id", "ordPathTimeseries")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.areaTimeseries)
            .style("fill", " url(#gradient2" + this.uniqueId + ")");
        }
      }

      if (this.ord2Array && this.ord2Array.length > 0 && this.enableLine2) {
        if (!d3.select("#ord2PathLine").empty()) {
          d3.select("#ord2PathLine")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.rightLine);
        } else {
          this.chart
            .append("path")
            .attr("id", "ord2PathLine")
            .datum(this.preparedData)
            .attr("d", this.rightLine)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("fill", "transparent")
            .style("stroke", "url(#gradient2" + this.uniqueId + ")")
            .style("stroke-width", this.linesWidth);
        }
      }

      if (this.ord1Array && this.ord1Array.length > 0 && this.enableArea1) {
        if (!d3.select("#ord1PathArea1").empty()) {
          d3.select("#ord1PathArea1")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.leftArea1);

          d3.select("#ord1PathArea2")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.leftArea2);

          d3.select("#ord1PathArea3")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.leftArea3);

          d3.select("#ord1PathArea4")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.leftArea4);
        } else {
          this.chart
            .append("path")
            .attr("id", "ord1PathArea2")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.leftArea2)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("transform-origin", "0px " + this.chartHeight + "px")
            .style("fill", " url(#gradient1" + this.uniqueId + ")")
            .style("opacity", "0.3");

          this.chart
            .append("path")
            .attr("id", "ord1PathArea3")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.leftArea3)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("transform-origin", "0px " + this.chartHeight + "px")
            .style("fill", " url(#gradient1" + this.uniqueId + ")")
            .style("opacity", "0.2");

          this.chart
            .append("path")
            .attr("id", "ord1PathArea4")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.leftArea4)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("transform-origin", "0px " + this.chartHeight + "px")
            .style("fill", " url(#gradient1" + this.uniqueId + ")")
            .style("opacity", "0.2");

          this.chart
            .append("path")
            .attr("id", "ord1PathArea1")
            .classed("areaPath", true)
            .datum(this.preparedData)
            .attr("d", this.leftArea1)
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("transform-origin", "0px " + this.chartHeight + "px")
            .style("fill", " url(#gradient1" + this.uniqueId + ")")
            .style("opacity", "1");
        }
      }

      if (this.ord1Array && this.ord1Array.length > 0 && this.enableLine1) {
        if (!d3.select("#ord1PathLine").empty()) {
          d3.select("#ord1PathLine")
            .datum(this.preparedData)
            .transition()
            .duration(this.transitionSpeed)
            .attr("d", this.leftLine);
        } else {
          this.chart
            .append("path")
            .attr("id", "ord1PathLine")
            .datum(this.preparedData)
            .attr("d", this.leftLine)
            .style("fill", "transparent")
            .style("clip-path", "url(#clipChart" + this.uniqueId + ")")
            .style("stroke", "url(#gradient1" + this.uniqueId + ")")
            .style("stroke-width", this.linesWidth);
        }
      }

      // Cosmetic purpose: removing dashes and line displayed by default
      this.svgTSRef.selectAll(".domain").remove();
      this.svgTSRef.selectAll(".tick line").remove();

      let brushg = this.timeseries
        .append("g")
        .attr("class", "x brush")
        .call(this.brush)
        .call(this.brush.move, this.xScale.range())
        .select(".selection")
        .style("fill", " url(#gradient1" + this.uniqueId + ")")
        .attr("stroke", "none");

      setTimeout(() => {
        this.animLoops();
      }, this.transitionSpeed + 100);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.timeseries {
  position: relative;
  // background: black;
  // background: #191919;
  @include themeBg1();

  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .numbox {
    position: absolute;
    left: 30px;
    bottom: 10px;
    // color: #b4b4b4;
    @include fontColor();

    font-size: 12px;
    text-align: left;
    margin-left: 20px;
    .num {
      color: #33a8ee;
    }
  }
  .shadow {
    text-align: center;
    .svgg {
      margin: 0 auto;
    }
  }
  #tcl-chart-widget {
    height: 100%;
    width: 100%;
  }
  .legend {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    height: 40px;
    line-height: 40px;
    width: 100%;
  }

  .legend > div {
    // flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  .legend .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    align-self: center;
    display: inline-block;
  }

  .legend .descr {
    margin: 0 0.5rem;
    // color: #fff;
    @include fontColor();
  }
  .legend .dot::after {
    content: " ";
  }

  .legend .hide {
    display: none;
  }
  .tcl-chart-container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    height: 100%;
    width: 100%;
  }

  .brush .extent {
    fill-opacity: 0.07;
    shape-rendering: crispEdges;
    clip-path: url(#clip);
  }

  #tcl-chart-widget .pane {
    cursor: move;
    fill: none;
    pointer-events: none;
  }
  /* brush handles  */
  .resize .handle {
    fill: #555;
  }

  .resize .handle-mini {
    fill: white;
    stroke-width: 1px;
    stroke: #555;
  }

  .scale_button {
    cursor: pointer;
  }
  .scale_button rect {
    fill: #eaeaea;
  }
  .scale_button:hover text {
    fill: #417dd6;
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}
</style>
