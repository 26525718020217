import req from "./http";
//基础地址
// const base = "http://129.211.158.233:8001/";
let base = "";
//正式环境
let prodBase = "https://service.smartfindata.com/";
if (process.env.VUE_APP_SECRET == "dev") {
  base = "http://129.211.158.233:8001/";
} else if (process.env.VUE_APP_SECRET == "staging") {
  base = "https://demo-api.smartfindata.com/buzz/";
} else if (process.env.VUE_APP_SECRET == "prod") {
  base = "https://api.smartfindata.com/buzz/";
}
let logoBase = "https://api.smartfindata.com/buzz/logo/v1";
let tempBase ="https://demo-api.smartfindata.com/buzz/"

// 情感指标是 sentiment
export function getInit(params) {
  return req.get(base + `${params}`, {});
}
export function checkAuth(params) {
  return req.get(base + `backend/v1/auth?token=${params}`, {});
  // return req.get(base + `backend/v1/auth?token=${params}`, {});
}
//添加自选股
export function addSelfStock(params) {
  return req.post(base + `watchlist/v1/add`, params);
  // return req.get(base + `backend/v1/auth?token=${params}`, {});
}
//删除自选股
export function delSelfStock(params) {
  return req.post(base + `watchlist/v1/del`, params);
}
/**
 * 正式环境鉴权
 */
export function prodCheckAuth(data) {
  return req.post(prodBase + `v1/token/auth/client`, data);
}
/**
 * create by jack 2020-8-30
 * 测试鉴权
 */
export function newCheckAuth(data) {
  return req.post(prodBase + `v1/token/auth/user`, data);
}
export function getLogo(params) {
  return req.get(logoBase + `${params}`, {});
}
//获取动态消息
export function getNewsOrigin(params) {
  return req.get(tempBase + `factor/v1`, {});
}
