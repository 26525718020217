let ord1 = [
  0.0631411,
  0.0563517,
  0.0336133,
  0.0342883,
  0.0464559,
  0.0602966,
  0.0456092,
  0.0373042,
  0.0421146,
  0.0700897,
  0.0491666,
  0.0799352,
  0.0807332,
  0.0812497,
  0.0799559,
  0.0516311,
  0.0354465,
  0.0386347,
  0.05772,
  0.0473884,
  0.0533002,
  0.061245,
  0.0572199,
  0.0788476,
  0.0784924,
  0.0488035,
  0.0545544,
  0.0662648,
  0.1033811,
  0.1038152
];
// ord1 = [153,789,124,563,355,243,399];
// ord1 = [1000, 1500, 800, 900, 600, 1000, 1300];
// ord1 =[10, 15, 8, 9, 6, 10, 13];

let ord2 = [
  7320.591,
  7305.887,
  7213.164,
  7385.962,
  7469.319,
  7341.79,
  7229.5,
  7200.481,
  7197.164,
  7239.092,
  6886.961,
  6614.9988,
  7289.998,
  7150.704,
  7199.258,
  7324.34,
  7231.739,
  7190.224,
  7196.366,
  7219.947,
  7224.215,
  7178.244,
  7179.387,
  6947.708,
  7269.337,
  7781.204,
  8169.923,
  8053.556,
  7825.568,
  8056.131
];
let abs1Array = [
  "2020-02-24T00:00:00.000+00:00",
  "2020-02-25T00:00:00.000+00:00",
  "2020-02-26T00:00:00.000+00:00",
  "2020-02-27T00:00:00.000+00:00",
  "2020-02-28T00:00:00.000+00:00",
  "2020-02-29T00:00:00.000+00:00",
  "2020-03-01T00:00:00.000+00:00",
  "2020-03-02T00:00:00.000+00:00",
  "2020-03-03T00:00:00.000+00:00",
  "2020-03-04T00:00:00.000+00:00",
  "2020-03-05T00:00:00.000+00:00",
  "2020-03-06T00:00:00.000+00:00",
  "2020-03-07T00:00:00.000+00:00",
  "2020-03-10T00:00:00.000+00:00",
  "2020-03-11T00:00:00.000+00:00",
  "2020-03-12T00:00:00.000+00:00",
  "2020-03-13T00:00:00.000+00:00",
  "2020-03-14T00:00:00.000+00:00",
  "2020-03-15T00:00:00.000+00:00",
  "2020-03-16T00:00:00.000+00:00",
  "2020-03-17T00:00:00.000+00:00",
  "2020-03-18T00:00:00.000+00:00",
  "2020-03-19T00:00:00.000+00:00",
  "2020-03-20T00:00:00.000+00:00",
  "2020-03-21T00:00:00.000+00:00",
  "2020-03-22T00:00:00.000+00:00",
  "2020-03-23T00:00:00.000+00:00",
  "2020-03-24T00:00:00.000+00:00",
  "2020-03-25T00:00:00.000+00:00",
  "2020-03-26T00:00:00.000+00:00"
];
let bubblesDatas = [
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_21368",
      mappedInstrumentId: "US-16435",
      name: "Morgan Stanley",
      sector: "10330010",
      symbol: "MS",
      type: "stock"
    },
    news: 668
  },
  {
    blogs: 5,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_01345",
      mappedInstrumentId: "US-130652",
      name: "Alphabet Inc",
      sector: "30830010",
      symbol: "GOOGL",
      type: "stock"
    },
    news: 581
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_17271",
      mappedInstrumentId: "US-16227",
      name: "JPMorgan Chase & Co",
      sector: "10320010",
      symbol: "JPM",
      type: "stock"
    },
    news: 586
  },
  {
    blogs: 6,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_01498",
      mappedInstrumentId: "US-123785",
      name: "Amazon.com Inc",
      sector: "10280060",
      symbol: "AMZN",
      type: "stock"
    },
    news: 513
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_02012",
      mappedInstrumentId: "US-123705",
      name: "Apple Inc",
      sector: "31120030",
      symbol: "AAPL",
      type: "stock"
    },
    news: 430
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "CH",
      entityId: "EQY_33813",
      mappedInstrumentId: "CH-4871864",
      name: "UBS Group AG",
      sector: null,
      symbol: "UBSG",
      type: "stock"
    },
    news: 425
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_35250",
      mappedInstrumentId: "US-345170",
      name: "Wells Fargo Real Estate Investment Corp",
      sector: "10420090",
      symbol: "WFE.PR.A",
      type: "stock"
    },
    news: 351
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_06827",
      mappedInstrumentId: "US-15710",
      name: "Citigroup Inc",
      sector: "10320010",
      symbol: "C",
      type: "stock"
    },
    news: 335
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "CH",
      entityId: "EQY_07734",
      mappedInstrumentId: "CH-4871845",
      name: "Credit Suisse Group AG",
      sector: null,
      symbol: "CSGN",
      type: "stock"
    },
    news: 333
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "CA",
      entityId: "EQY_27164",
      mappedInstrumentId: "CA-118775",
      name: "Royal Bank of Canada",
      sector: "10320010",
      symbol: "RY",
      type: "stock"
    },
    news: 330
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_01577",
      mappedInstrumentId: "US-17070",
      name: "American Vanguard Corp",
      sector: "10110010",
      symbol: "AVD",
      type: "stock"
    },
    news: 317
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_12724",
      mappedInstrumentId: "US-16086",
      name: "Goldman Sachs Group Inc",
      sector: "10330010",
      symbol: "GS",
      type: "stock"
    },
    news: 311
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_23204",
      mappedInstrumentId: "US-21577",
      name: "Nuveen AMT-Free Municipal Income Fund",
      sector: "31030010",
      symbol: "NEA",
      type: "stock"
    },
    news: 303
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_08613",
      mappedInstrumentId: "DE-337153",
      name: "Deutsche Bank AG",
      sector: "10320020",
      symbol: "DBK",
      type: "stock"
    },
    news: 297
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_04341",
      mappedInstrumentId: "US-304683",
      name: "BlackRock Inc",
      sector: "10310010",
      symbol: "BLK",
      type: "stock"
    },
    news: 284
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_10708",
      mappedInstrumentId: "US-308415",
      name: "Facebook Inc",
      sector: "30830010",
      symbol: "FB",
      type: "stock"
    },
    news: 255
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_03538",
      mappedInstrumentId: "UK-146361",
      name: "Barclays PLC",
      sector: "10320010",
      symbol: "BARC",
      type: "stock"
    },
    news: 242
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_23656",
      mappedInstrumentId: "US-17510",
      name: "Oppenheimer Holdings Inc",
      sector: "10330010",
      symbol: "OPY",
      type: "stock"
    },
    news: 218
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_24907",
      mappedInstrumentId: "US-17591",
      name: "Piper Jaffray Cos",
      sector: "10310010",
      symbol: "PJC",
      type: "stock"
    },
    news: 200
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_31241",
      mappedInstrumentId: "US-16821",
      name: "SunTrust Banks Inc",
      sector: "10320020",
      symbol: "STI",
      type: "stock"
    },
    news: 191
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_20807",
      mappedInstrumentId: "US-124610",
      name: "Microsoft Corp",
      sector: "31110030",
      symbol: "MSFT",
      type: "stock"
    },
    news: 187
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_16239",
      mappedInstrumentId: "US-184629",
      name: "Invesco Ltd",
      sector: "10310010",
      symbol: "IVZ",
      type: "stock"
    },
    news: 165
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_35178",
      mappedInstrumentId: "JP-175649",
      name: "Wealth Management Inc",
      sector: "30830010",
      symbol: "3772",
      type: "stock"
    },
    news: 163
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_04490",
      mappedInstrumentId: "US-15616",
      name: "Boeing Co",
      sector: "31010010",
      symbol: "BA",
      type: "stock"
    },
    news: 158
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_23023",
      mappedInstrumentId: "US-124683",
      name: "Northern Trust Corp",
      sector: "10310010",
      symbol: "NTRS",
      type: "stock"
    },
    news: 157
  },
  {
    blogs: 3,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_30168",
      mappedInstrumentId: "JP-164588",
      name: "SoftBank Group Corp",
      sector: "30810010",
      symbol: "9984",
      type: "stock"
    },
    news: 138
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "KR",
      entityId: "EQY_27639",
      mappedInstrumentId: "KR-396487",
      name: "Samsung C&T Corp",
      sector: "31040010",
      symbol: "02826K",
      type: "stock"
    },
    news: 129
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "CN",
      entityId: "EQY_14932",
      mappedInstrumentId: "CN-327821",
      name: "Dinglong Culture Co Ltd",
      sector: "10290020",
      symbol: "002502",
      type: "stock"
    },
    news: 121
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_35754",
      mappedInstrumentId: "US-17058",
      name: "Xerox Holdings Corp",
      sector: "31110010",
      symbol: "XRX",
      type: "stock"
    },
    news: 110
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "IN",
      entityId: "EQY_15835",
      mappedInstrumentId: "IN-171174",
      name: "Infosys Ltd",
      sector: "31110010",
      symbol: "INFY",
      type: "stock"
    },
    news: 107
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_03454",
      mappedInstrumentId: "US-15651",
      name: "Bank of New York Mellon Corp",
      sector: "10310010",
      symbol: "BK",
      type: "stock"
    },
    news: 103
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_03423",
      mappedInstrumentId: "US-15617",
      name: "Bank of America Corporation",
      sector: "10320010",
      symbol: "BAC",
      type: "stock"
    },
    news: 96
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_14840",
      mappedInstrumentId: "US-17264",
      name: "HP Inc",
      sector: "31120020",
      symbol: "HPQ",
      type: "stock"
    },
    news: 90
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "HK",
      entityId: "EQY_07252",
      mappedInstrumentId: "HK-162350",
      name: "Common Splendor International Health Industry Group Ltd",
      sector: "10310010",
      symbol: "0286",
      type: "stock"
    },
    news: 87
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_22233",
      mappedInstrumentId: "US-126728",
      name: "Netflix Inc",
      sector: "30820040",
      symbol: "NFLX",
      type: "stock"
    },
    news: 85
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_07970",
      mappedInstrumentId: "UK-240237",
      name: "CVS Group PLC",
      sector: "10260010",
      symbol: "CVSG",
      type: "stock"
    },
    news: 84
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "AU",
      entityId: "EQY_19690",
      mappedInstrumentId: "AU-234164",
      name: "Macquarie Group Ltd",
      sector: "10330010",
      symbol: "MQG",
      type: "stock"
    },
    news: 81
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_01174",
      mappedInstrumentId: "US-343211",
      name: "Alibaba Group Holding Ltd",
      sector: "10280060",
      symbol: "BABA",
      type: "stock"
    },
    news: 76
  },
  {
    blogs: 4,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_16039",
      mappedInstrumentId: "US-124419",
      name: "Intel Corp",
      sector: "31130020",
      symbol: "INTC",
      type: "stock"
    },
    news: 72
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_16101",
      mappedInstrumentId: "US-16168",
      name: "International Business Machines Corp",
      sector: "31110010",
      symbol: "IBM",
      type: "stock"
    },
    news: 72
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "HK",
      entityId: "EQY_35811",
      mappedInstrumentId: "HK-4894866",
      name: "Xiaomi Corp",
      sector: null,
      symbol: "1810",
      type: "stock"
    },
    news: 68
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "IN",
      entityId: "EQY_12819",
      mappedInstrumentId: "IN-4838626",
      name: "Goyal Associates Ltd",
      sector: null,
      symbol: "530663",
      type: "stock"
    },
    news: 68
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_11693",
      mappedInstrumentId: "JP-163318",
      name: "FUJIFILM Holdings Corp",
      sector: "31020010",
      symbol: "4901",
      type: "stock"
    },
    news: 63
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_14855",
      mappedInstrumentId: "UK-146400",
      name: "HSBC Holdings PLC",
      sector: "10320010",
      symbol: "HSBA",
      type: "stock"
    },
    news: 61
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "ID",
      entityId: "EQY_25866",
      mappedInstrumentId: "ID-165236",
      name: "PT Vale Indonesia Tbk",
      sector: "10150030",
      symbol: "INCO",
      type: "stock"
    },
    news: 58
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_34559",
      mappedInstrumentId: "US-17000",
      name: "Verizon Communications Inc",
      sector: "30810010",
      symbol: "VZ",
      type: "stock"
    },
    news: 57
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_17855",
      mappedInstrumentId: "US-16241",
      name: "KeyCorp",
      sector: "10320020",
      symbol: "KEY",
      type: "stock"
    },
    news: 56
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_06804",
      mappedInstrumentId: "US-124054",
      name: "Cisco Systems Inc",
      sector: "31120010",
      symbol: "CSCO",
      type: "stock"
    },
    news: 56
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "CA",
      entityId: "EQY_20231",
      mappedInstrumentId: "CA-121355",
      name: "Maxim Power Corp",
      sector: "20710010",
      symbol: "MXG",
      type: "stock"
    },
    news: 56
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_21131",
      mappedInstrumentId: "JP-173424",
      name: "Mizuho Financial Group Inc",
      sector: "10320020",
      symbol: "8411",
      type: "stock"
    },
    news: 51
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_02653",
      mappedInstrumentId: "UK-146359",
      name: "AstraZeneca PLC",
      sector: "20620010",
      symbol: "AZN",
      type: "stock"
    },
    news: 49
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_35958",
      mappedInstrumentId: "JP-173929",
      name: "Yahoo Japan Corp",
      sector: "30830010",
      symbol: "4689",
      type: "stock"
    },
    news: 50
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_18737",
      mappedInstrumentId: "US-183426",
      name: "Ladenburg Thalmann Financial Services Inc",
      sector: "10330010",
      symbol: "LTS",
      type: "stock"
    },
    news: 48
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_34896",
      mappedInstrumentId: "UK-146473",
      name: "Vodafone Group PLC",
      sector: "30810010",
      symbol: "VOD",
      type: "stock"
    },
    news: 46
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_16680",
      mappedInstrumentId: "US-4464615",
      name: "Janus Henderson Group PLC",
      sector: null,
      symbol: "JHG",
      type: "stock"
    },
    news: 47
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_06049",
      mappedInstrumentId: "US-17167",
      name: "Chevron Corp",
      sector: "30910030",
      symbol: "CVX",
      type: "stock"
    },
    news: 46
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "BD",
      entityId: "EQY_15831",
      mappedInstrumentId: "BD-306152",
      name: "Information Services Network Ltd",
      sector: "31110010",
      symbol: "ISNLTD",
      type: "stock"
    },
    news: 46
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_00287",
      mappedInstrumentId: "US-127456",
      name: "Acacia Research Corp",
      sector: "31020010",
      symbol: "ACTG",
      type: "stock"
    },
    news: 45
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "ES",
      entityId: "EQY_02026",
      mappedInstrumentId: "ES-340848",
      name: "Applus Services SA",
      sector: "31020010",
      symbol: "APPS",
      type: "stock"
    },
    news: 44
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_31553",
      mappedInstrumentId: "US-16800",
      name: "Synovus Financial Corp",
      sector: "10320020",
      symbol: "SNV",
      type: "stock"
    },
    news: 43
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_27851",
      mappedInstrumentId: "DE-391963",
      name: "SAP SE",
      sector: "31110020",
      symbol: "SAP",
      type: "stock"
    },
    news: 43
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_35056",
      mappedInstrumentId: "US-17024",
      name: "Walmart Inc",
      sector: "20550010",
      symbol: "WMT",
      type: "stock"
    },
    news: 42
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "HK",
      entityId: "EQY_32247",
      mappedInstrumentId: "HK-174592",
      name: "Tencent Holdings Ltd",
      sector: "30830010",
      symbol: "0700",
      type: "stock"
    },
    news: 38
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_34911",
      mappedInstrumentId: "DE-392030",
      name: "Volkswagen AG",
      sector: "10200020",
      symbol: "VOW",
      type: "stock"
    },
    news: 39
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_03638",
      mappedInstrumentId: "DE-391706",
      name: "Bayerische Motoren Werke AG",
      sector: "10200020",
      symbol: "BMW",
      type: "stock"
    },
    news: 38
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "KR",
      entityId: "EQY_19087",
      mappedInstrumentId: "KR-167809",
      name: "LG Corp",
      sector: "31120030",
      symbol: "003550",
      type: "stock"
    },
    news: 36
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_22914",
      mappedInstrumentId: "JP-164508",
      name: "NOMURA Co Ltd",
      sector: "31040010",
      symbol: "9716",
      type: "stock"
    },
    news: 35
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_35041",
      mappedInstrumentId: "US-17002",
      name: "Walgreens Boots Alliance Inc",
      sector: "20645020",
      symbol: "WBA",
      type: "stock"
    },
    news: 35
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_07647",
      mappedInstrumentId: "US-135080",
      name: "Cowen Inc",
      sector: "10330010",
      symbol: "COWN",
      type: "stock"
    },
    news: 35
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_24700",
      mappedInstrumentId: "US-16614",
      name: "Pfizer Inc",
      sector: "20620010",
      symbol: "PFE",
      type: "stock"
    },
    news: 35
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_01260",
      mappedInstrumentId: "DE-391681",
      name: "Allianz SE",
      sector: "10340060",
      symbol: "ALV",
      type: "stock"
    },
    news: 34
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_06834",
      mappedInstrumentId: "US-343375",
      name: "Citizens Financial Group Inc",
      sector: "10320020",
      symbol: "CFG",
      type: "stock"
    },
    news: 34
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_01297",
      mappedInstrumentId: "US-340361",
      name: "Ally Financial Inc",
      sector: "10320030",
      symbol: "ALLY",
      type: "stock"
    },
    news: 33
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_32310",
      mappedInstrumentId: "US-303648",
      name: "Tesla Inc",
      sector: "10200020",
      symbol: "TSLA",
      type: "stock"
    },
    news: 33
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_25562",
      mappedInstrumentId: "UK-146432",
      name: "Prudential PLC",
      sector: "10340010",
      symbol: "PRU",
      type: "stock"
    },
    news: 32
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "IN",
      entityId: "EQY_04080",
      mappedInstrumentId: "IN-172752",
      name: "Bharti Airtel Ltd",
      sector: "30810010",
      symbol: "BHARTIARTL",
      type: "stock"
    },
    news: 31
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_23686",
      mappedInstrumentId: "US-124721",
      name: "Oracle Corp",
      sector: "31110030",
      symbol: "ORCL",
      type: "stock"
    },
    news: 30
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_08929",
      mappedInstrumentId: "US-260008",
      name: "DNB Financial Corp",
      sector: "10320020",
      symbol: "DNBF",
      type: "stock"
    },
    news: 30
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "ZA",
      entityId: "EQY_02128",
      mappedInstrumentId: "ZA-239180",
      name: "ArcelorMittal South Africa Ltd",
      sector: "10160020",
      symbol: "ACL",
      type: "stock"
    },
    news: 30
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "MY",
      entityId: "EQY_12287",
      mappedInstrumentId: "MY-246500",
      name: "GE-Shen Corp Bhd",
      sector: "10200030",
      symbol: "7197",
      type: "stock"
    },
    news: 30
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "UK",
      entityId: "EQY_04457",
      mappedInstrumentId: "UK-137736",
      name: "BMO Capital & Income Investment Trust PLC",
      sector: "10310010",
      symbol: "BCI",
      type: "stock"
    },
    news: 29
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_26435",
      mappedInstrumentId: "US-16716",
      name: "Raymond James Financial Inc",
      sector: "10330010",
      symbol: "RJF",
      type: "stock"
    },
    news: 29
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_30741",
      mappedInstrumentId: "US-124935",
      name: "Starbucks Corp",
      sector: "10270010",
      symbol: "SBUX",
      type: "stock"
    },
    news: 28
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_26103",
      mappedInstrumentId: "US-124854",
      name: "Qualcomm Inc",
      sector: "31130020",
      symbol: "QCOM",
      type: "stock"
    },
    news: 28
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "CH",
      entityId: "EQY_25421",
      mappedInstrumentId: "CH-145052",
      name: "Private Equity Holding AG",
      sector: "10310010",
      symbol: "PEHN",
      type: "stock"
    },
    news: 27
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_07971",
      mappedInstrumentId: "US-15834",
      name: "CVS Health Corp",
      sector: "20630010",
      symbol: "CVS",
      type: "stock"
    },
    news: 27
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "FR",
      entityId: "EQY_04468",
      mappedInstrumentId: "FR-138791",
      name: "BNP Paribas",
      sector: "10320020",
      symbol: "BNP",
      type: "stock"
    },
    news: 26
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_32526",
      mappedInstrumentId: "US-16253",
      name: "The Kroger Co",
      sector: "20550030",
      symbol: "KR",
      type: "stock"
    },
    news: 27
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "IN",
      entityId: "EQY_15317",
      mappedInstrumentId: "IN-171150",
      name: "ICICI Bank Ltd",
      sector: "10320020",
      symbol: "ICICIBANK",
      type: "stock"
    },
    news: 27
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_13727",
      mappedInstrumentId: "US-124333",
      name: "Hancock Whitney Corp",
      sector: "10320020",
      symbol: "HWC",
      type: "stock"
    },
    news: 26
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_22596",
      mappedInstrumentId: "US-16479",
      name: "Nike Inc",
      sector: "10240030",
      symbol: "NKE",
      type: "stock"
    },
    news: 26
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "IN",
      entityId: "EQY_31981",
      mappedInstrumentId: "IN-173728",
      name: "Tata Motors Ltd",
      sector: "10200020",
      symbol: "TATAMOTORS",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 1,
    entity: {
      entityGroupId: "DE",
      entityId: "EQY_00491",
      mappedInstrumentId: "DE-336898",
      name: "adidas AG",
      sector: "10240030",
      symbol: "ADS",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_34247",
      mappedInstrumentId: "US-16961",
      name: "US Bancorp",
      sector: "10320020",
      symbol: "USB",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_30576",
      mappedInstrumentId: "US-325155",
      name: "Sprint Corp",
      sector: "30810010",
      symbol: "S",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 2,
    entity: {
      entityGroupId: "JP",
      entityId: "EQY_33287",
      mappedInstrumentId: "JP-163092",
      name: "Toyota Boshoku Corp",
      sector: "10200030",
      symbol: "3116",
      type: "stock"
    },
    news: 23
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_14690",
      mappedInstrumentId: "US-16139",
      name: "Honeywell International Inc",
      sector: "31070020",
      symbol: "HON",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_16831",
      mappedInstrumentId: "US-16309",
      name: "Jefferies Financial Group Inc",
      sector: "20525040",
      symbol: "JEF",
      type: "stock"
    },
    news: 25
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "US",
      entityId: "EQY_03651",
      mappedInstrumentId: "US-15622",
      name: "BB&T Corp",
      sector: "10320020",
      symbol: "BBT",
      type: "stock"
    },
    news: 24
  },
  {
    blogs: 0,
    entity: {
      entityGroupId: "FR",
      entityId: "EQY_30147",
      mappedInstrumentId: "FR-139320",
      name: "Societe Generale SA",
      sector: "10320020",
      symbol: "GLE",
      type: "stock"
    },
    news: 23
  }
];

let articleSample = [
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [],
    provider: "Benzinga",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [],
    provider: "Benzinga",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "blogblogblogNew Zealand university students in Hong Kong asked to return home",
    type: "blog",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "blogblogblogNew Zealand university students in Hong Kong asked to return home",
    type: "blog",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Benzinga",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Benzinga",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [],
    provider: "Twitter",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "blogblogblogome",
    type: "blog",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [],
    provider: "Twitter",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [],
    provider: "Twitter",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_123",
          mappedInstrumentId: null,
          name: "China",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_220",
          mappedInstrumentId: null,
          name: "Hong Kong",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "db3f9e062d892901f1514fda0150bddc927378c2",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/v/8/image.related.StuffLandscapeSixteenByNine.1420x800.1xxxtv.png/1573876644315.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:57:00.000+00:00",
    source: "Stuff.co.nz",
    title: "New Zealand university students in Hong Kong asked to return home",
    type: "news",
    url:
      "https://www.stuff.co.nz/national/education/117476995/new-zealand-university-students-in-hong-kong-asked-to-return-home"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  },
  {
    articleEntities: [
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_377",
          mappedInstrumentId: null,
          name: "New Zealand",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      },
      {
        entity: {
          entityGroupId: "GPE",
          entityId: "GPE_591",
          mappedInstrumentId: null,
          name: "Washington DC",
          sector: null,
          symbol: null,
          type: "geopolitical"
        },
        topics: []
      }
    ],
    articleId: "b4bd834f8a939f5a45d8447974aae7649538f54c",
    images: [
      "https://resources.stuff.co.nz/content/dam/images/1/x/x/x/k/n/image.related.StuffLandscapeSixteenByNine.1420x800.1xxwzv.png/1573874826916.jpg"
    ],
    provider: "Webhose",
    publishDate: "2019-11-16T21:27:00.000+00:00",
    source: "Stuff.co.nz",
    title:
      "University of Colorado's half-tonne buffalo mascot has to retire - she runs too fast",
    type: "news",
    url:
      "https://www.stuff.co.nz/sport/other-sports/117475915/university-of-colorados-halftonne-buffalo-mascot-has-to-retire--she-runs-too-fast"
  }
];

export { bubblesDatas, abs1Array, ord2, ord1, articleSample };
