var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"timeseries",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"titlebox"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"notice"},[_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.jltip == 1)?_c('p',[_vm._v(" “历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。 ")]):_vm._e()]),_c('el-button',{on:{"click":function($event){return _vm.toggleTips()}}},[_vm._v(" ? ")])],1)],1)]),(_vm.legendInHeader)?_c('div',{staticClass:"legend",staticStyle:{"width":"auto","margin-top":"0"},attrs:{"header":""}},[_c('div',{staticClass:"legend1"},[_c('div',{staticClass:"dot",staticStyle:{"border-radius":"0"},style:({
          'background-image':
            'radial-gradient(' + _vm.color1 + ', ' + _vm.color1 + ')'
        })}),_c('span',{staticClass:"descr",staticStyle:{"font-size":"0.8rem","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.label1))])]),_c('div',{staticClass:"legend2",class:_vm.enableArea2 || _vm.enableLine2 ? '' : 'hide',staticStyle:{"text-align":"right"},style:({ 'padding-right': _vm.marginRight + 'px' })},[_c('div',{staticClass:"dot",staticStyle:{"border-radius":"0"},style:({
          'background-image':
            'radial-gradient(' + _vm.color4 + ', ' + _vm.color3 + ')'
        })}),_c('span',{staticClass:"descr",staticStyle:{"font-size":"0.8rem","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.label2))])])]):_vm._e(),_c('div',{staticClass:"tcl-chart-container"},[_c('span',{staticStyle:{"flex":"1 1 auto"}}),_c('svg',{staticClass:"timeseries_linesvg",style:({ width: _vm.width + 'px', height: 300 + 'px' }),attrs:{"id":"tcl-chart-widget"}},[_c('defs',[_c('linearGradient',{attrs:{"id":'gradient1' + _vm.uniqueId,"x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{attrs:{"offset":"25%","stop-color":_vm.color1}}),_c('stop',{attrs:{"offset":"100%","stop-color":_vm.color2}})],1),_c('linearGradient',{attrs:{"id":'gradient2' + _vm.uniqueId,"x1":"0%","y1":"0%","x2":"0%","y2":"100%"}},[_c('stop',{attrs:{"offset":"25%","stop-color":_vm.color3}}),_c('stop',{attrs:{"offset":"100%","stop-color":_vm.color4}})],1)],1)]),(!_vm.legendInHeader)?_c('div',{staticClass:"legend"},[_c('div',{staticClass:"legend1",style:({ 'padding-left': _vm.marginLeft + 'px' })},[_c('div',{staticClass:"dot",style:({
            'background-image':
              'radial-gradient(' + _vm.color2 + ', ' + _vm.color1 + ')'
          })}),_c('span',{staticClass:"descr"},[_vm._v(_vm._s(_vm.label1))])]),_c('div',{staticClass:"legend2",class:_vm.enableArea2 || _vm.enableLine2 ? '' : 'hide',staticStyle:{"text-align":"right"},style:({ 'padding-right': _vm.marginRight + 'px' })},[_c('span',{staticStyle:{"flex":"1 1 auto"}}),_c('span',{staticClass:"descr"},[_vm._v(_vm._s(_vm.label2))]),_c('div',{staticClass:"dot",style:({
            'background-image':
              'radial-gradient(' + _vm.color4 + ', ' + _vm.color3 + ')'
          })})])]):_vm._e(),_c('span',{staticStyle:{"flex":"1 1 auto"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }