import Vue from "vue";
import VueRouter from "vue-router";
import HK from "../views/HK.vue";
import { base } from "@/request/base.js";
import store from "./../store";
import { local } from "d3";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    redirect: "/main"
  },
  {
    path: "/main",
    name: "main",
    redirect: "US",
    component: () => import("../views/home.vue"),
    children: [
      {
        path: "/US",
        name: "US",
        component: HK
      },
      {
        path: "/self",
        name: "self",
        component: HK
      },
      {
        path: "/A",
        name: "A",
        component: HK
      },
      {
        path: "/HK",
        name: "HK",
        component: HK
      },

      {
        path: "/EX",
        name: "EX",
        component: HK
      },
      {
        path: "/FOREX",
        name: "FOREX",
        component: HK
      },
      {
        path: "/INDICES",
        name: "INDICES",
        component: HK
      },
      {
        path: "/COMMODITIES",
        name: "COMMODITIES",
        component: HK
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/detail.vue")
      }
    ]
  },

  {
    path: "/noAuth",
    name: "noAuth",
    component: () => import("../views/siglepage/403.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // console.log(to);

  // console.log(base);
  // console.log(base[to.name]);
  new Promise((resolve, reject) => {
    localStorage.setItem("pageIcon", base[to.name]);
    resolve();
  }).then(() => {
    next();
  });
  // console.log(store.getters.home / getCurrPage);
});

export default router;
