<template>
  <div class="page">
    <div
      class="circleprogress"
      :style="{ width: width + 'px', height: height + 'px' }"
    >
      <div class="titlebox">
        <div class="title">{{ title }}</div>
        <div class="notice">
          <el-tooltip placement="bottom" effect="light">
            <div slot="content">
              <p v-if="jltip == 1">
                “情绪表现“模块衡量了大众人群对一个实体或资产的集体情绪感觉。其中0表示非常消极，100表示非常积极。
              </p>
            </div>
            <el-button @click="toggleTips()"> ? </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="center">
        <svg width="220" height="180" class="svgbox" :id="this.otherId">
          <circle
            cx="110"
            cy="90"
            r="80"
            stroke="grey"
            stroke-width="16"
            fill="none"
            stroke-linecap="round"
            stroke-dashoffset="-220"
          />

          <circle
            cx="110"
            cy="90"
            r="80"
            id="testcirlce"
            class="circle_1"
            v-if="showCircle"
            stroke-width="16"
            fill="none"
            stroke-dasharray="0 600"
            stroke-dashoffset="-128"
            stroke-linecap="round"
          />
          <defs>
            <pattern
              :id="this.id"
              x="0"
              y="0"
              width="1"
              height="1"
              patternUnits="objectBoundingBox"
              patternTransform="rotate(0, 110, 110)"
            >
              <rect
                x="0"
                y="0"
                width="60"
                height="180"
                style="fill: #f53f3f;"
                ref="leftcircle"
              ></rect>

              <rect x="90" y="0" width="4" height="180"></rect>
              <rect
                class="circle_right"
                x="64"
                y="0"
                width="60"
                height="180"
                style="fill: #1e80ff;"
              ></rect>
            </pattern>
          </defs>
          <!-- <el-tooltip class="item" effect="light" content="Top Left 提示文字"> -->
          <!-- </el-tooltip> -->

          <circle
            cx="110"
            cy="90"
            r="62"
            :style="getStyle()"
            @mouseover="onHover"
          ></circle>
        </svg>
        <div class="icontest" style="">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="hover"
            :content="`新闻类情感分数:${data1}`"
          >
            <div class="left" slot="reference">
              <div class="" style="color:#fff;">{{ data1 }}</div>
              <i class="el-icon-document" style="color:#fff;"></i>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="hover"
            :content="`社交媒体类情感分数:${data2}`"
          >
            <div class="right" slot="reference">
              <div class="" style="color:#fff;">
                {{ data2 }}
              </div>
              <i class="el-icon-chat-dot-round" style="color:#fff;"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="numbox">
        <span class="mood">{{ comment }}</span>
        <span>({{ Math.floor(callData.total.sentiment * 100) }}/100)</span>
      </div>
      <div class="switchbtn">
        <div class="all" @click="changeTab('all')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="notall"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqdiqiuquanqiu"
              :class="{ activeicon: active == 'all' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
        <div class="zh" @click="changeTab('cs')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="notcs"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqzhongwen"
              :class="{ activeicon: active == 'cs' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
        <div class="en" @click="changeTab('en')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="noten"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqyingwen"
              :class="{ activeicon: active == 'en' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
      </div>

      <div calss="standard" id="circle_progress_iconbox">
        <ul>
          <li class="iconbox">
            <span
              class="iconfontyuqing icon-yqtaiyang"
              style="color:yellow;margin-left: -2px;"
            ></span>
          </li>
          <li class="red">
            <p></p>
          </li>
          <li class="orange">
            <span></span>
          </li>
          <li class="blue">
            <span></span>
          </li>
          <li class="green">
            <span></span>
          </li>
          <li class="blue1">
            <span></span>
          </li>
          <li class="iconbox">
            <span
              class="iconfontyuqing icon-yqshandian"
              style="color:grey;"
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import tools from "@/utils/tools";
import { timeThursdays } from "d3";

export default {
  name: "circleprogress",
  mixins: [tools],
  data() {
    return {
      arrayList: [],
      fontsize: 12,
      baseChartInfo: {},
      percentage: 20,
      customColor: "#0BA3F0",
      dashNum1: 300,
      dashNum2: 600,
      callData: {},
      data1: 0,
      data2: 0,
      comment: null,
      showCircle: true,
      active: "all",
      notall: false,
      notcs: false,
      noten: false
    };
  },
  props: {
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 260
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    title: {
      type: String,
      default: "情绪表现"
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      return this.getID();
    },
    otherId: function() {
      return this.getOtherID();
    }
  },
  watch: {
    data(newVal) {
      this.arrayList = newVal;
    },
    jldata: function(newVal) {
      let _this = this;
      this.notall = false;
      this.notcs = false;
      this.noten = false;
      this.active = "all";
      setTimeout(function() {
        _this.changeTab("all");
      }, 0);
    }
  },
  created() {},
  mounted() {
    let _this = this;
    this.noten = false;
    this.notcs = false;
    this.notall = false;
    setTimeout(function() {
      _this.changeTab("all");
    }, 0);
  },
  methods: {
    onHover() {
      console.log("hover");
    },
    getStyle() {
      return `stroke: #000000; fill: url(#${this.id}`;
    },
    filterData(e) {
      if (e.total.sentiment <= 0.2) {
        this.comment = "非常悲观";
      } else if (0.2 < e.total.sentiment && e.total.sentiment <= 0.4) {
        this.comment = "悲观";
      } else if (0.4 < e.total.sentiment && e.total.sentiment <= 0.6) {
        this.comment = "中立";
      } else if (0.6 < e.total.sentiment && e.total.sentiment <= 0.8) {
        this.comment = "乐观";
      } else if (0.8 < e.total.sentiment) {
        this.comment = "非常乐观";
      }
    },
    changeTab(params) {
      let tempData = this.jldata.sentiments;
      let nodata = true;
      this.noten = false;
      this.notcs = false;
      this.notall = false;

      tempData.forEach(item => {
        if (item.language == params) {
          nodata = false;
          this.callData = item;
          this.filterData(item);
          this.active = params;
          this.data1 = Math.floor(this.callData.news.sentiment * 100);
          this.data2 = Math.floor(this.callData.social.sentiment * 100);
          this.initChart();
        }
      });
      if (nodata) {
        if (params == "all") {
          this.notall = true;
          setTimeout(() => {
            this.notall = true;
          }, 3000);
        }
        if (params == "cs") {
          this.notcs = true;
          setTimeout(() => {
            this.notcs = false;
          }, 3000);
        }
        if (params == "en") {
          this.noten = true;
          setTimeout(() => {
            this.noten = false;
          }, 3000);
        }
      }
    },
    initChart() {
      let _this = this;

      let circleLong = Math.PI * 80 * 2;
      let colorLong = 0;
      if (this.callData.total.sentiment > 0) {
        colorLong = Math.floor(this.callData.total.sentiment * circleLong);
      }
      let low = circleLong - colorLong;
      let temp = this.callData.total.sentiment;
      let tempcolor = "#fff";
      if (temp <= 0.2) {
        tempcolor = "#00d25b";
      } else if (0.2 < temp && temp <= 0.4) {
        tempcolor = "#25ff83";
      } else if (0.4 < temp && temp <= 0.6) {
        tempcolor = "#f39c00";
      } else if (0.6 < temp && temp <= 0.8) {
        tempcolor = "#fd9297";
      } else if (0.8 < temp) {
        tempcolor = "#fc2e37";
      }

      let circle = d3.select(".circle_1");
      console.log(circle);
      circle
        .attr("stroke-dasharray", `${colorLong} ${low}`)
        .attr("stroke", tempcolor);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themeBg1();
}
.circleprogress {
  width: 100%;

  position: relative;
  .titlebox {
    display: flex;

    height: 30px;
    align-items: center;
    margin-left: 10px;
    // margin-top: 6px;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }

  .center {
    width: 100%;

    position: relative;
  }
  .center_circle {
    position: absolute;
  }

  .icontest {
    position: absolute;
    top: 110px;
    left: 110px;
    width: 80px;
    height: 60px;
    // margin-left: -40px;
    margin-top: -30px;
  }
  .numbox {
    height: 30px;
    line-height: 30px;

    color: #fff;
  }
  .switchbtn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    div {
      span {
        color: #fff;
      }
    }
    .all {
      margin-left: 20px;
      cursor: pointer;
    }
    .zh {
      margin-left: 40px;
      cursor: pointer;
    }
    .en {
      margin-left: 40px;
      cursor: pointer;
    }
    .activeicon {
      color: #fff !important;
    }
  }
}
#circle_progress_iconbox {
  width: 10px;
  height: 140px;
  position: absolute;
  right: 20px;
  top: 50px;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      span {
        display: inline-block;
        width: 4px;
        height: 20px;
      }
      p {
        display: inline-block;
        width: 4px;
        height: 20px;
      }
    }
    .iconbox {
      margin-left: -2px;
    }
    .red {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      p {
        background: #fc2e37;
      }
    }
    .orange {
      span {
        background: #fd9297;
      }
    }
    .blue {
      span {
        background: #f39c00;
      }
    }
    .green {
      span {
        background: #25ff83;
      }
    }
    .blue1 {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      span {
        background: #00d25b;
      }
    }
  }
}
</style>
