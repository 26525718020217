<template>
  <div class="bubblebox">
    <div id="circle_chart" class="circle_box" v-if="data.length > 0"></div>
    <div v-else style="color:#fff;">暂无数据</div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { gsap, MorphSVGPlugin } from "gsap/all";
import { mapActions } from "vuex";
import {
  ord1,
  ord2,
  abs1Array,
  bubblesDatas,
  articleSample
} from "./../gsap/linedata";

// let  thems =window.document.documentElement.getAttribute('data-theme')
export default {
  name: "bubble",
  data() {
    return {
      arrayList: [],
      fontsize: 12,
      timelineActive: "#fdca64",
      chartSvg: "",
      chartZoom: "",
      percentage: 20,
      customColor: "#0BA3F0",
      loading: true,
      tooltipData: {},
      currType: 1,
      activeColor: 0,
      // new bubble，
      color1:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#f39c02"
          : "#f39c02",
      color2: "#e42349",
      color3: "green",
      color4: "grey",
      //色值 填充色
      f_color1: "#00d25b",
      f_color2: "#25ff83",
      f_color3: "#f39c00",
      f_color4: "#fd9297",
      f_color5: "#fc2e37",
      f_color6: "green",
      f_color7: "#467fae",

      f_type2color1: "#0065ca",
      f_type2color2: "#0097fe",
      f_type2color3: "#929292",
      f_type2color4: "#e42349",
      f_type2color5: "#730f0f",
      f_type2color6: "green",
      f_type2color7: "#467fae",
      f_color8:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#191919"
          : "#fff",
      f_color9: "transparent",
      bubbleColorBg:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? "#191919"
          : "#fff",

      clickMessageOpen: false,
      parentSubject: null,
      rankingBroadcastSubscription: null,
      chartElement: null,
      isTrending: false,
      svg: null,
      svgWidth: 0,
      svgHeight: 0,
      viewBoxX: 2000,
      viewBoxY: 2000,
      gradient: null,
      chart: null,
      scaleRadius: null,
      scaleInnerRadius: null,
      scaleFont: null,
      simulation: null,
      // For circles parameteres
      padding: 10,
      scaleInnerRadiusMin: 45,
      scaleInnerRadiusMax: 205,
      scaleOuterRadiusMin: 55,
      scaleOuterRadiusMax: 225,
      // zoomHoverRadius: this.scaleInnerRadiusMax * 2,
      zoomHoverRadius: 205 * 2,
      // For zooming
      zoomValue: 0.75,
      zoom: null,
      zoomLayer: null,
      zoomScaleMin: 1 / 2,
      zoomScaleMax: 1,
      // circleSize: (0.75 - 0.5) * 200,
      // for slider
      autoTicks: false,
      disabled: false,
      invert: false,
      // max: this.zoomScaleMax,
      // min: this.zoomScaleMin,
      max: 2,
      min: 1 / 2,
      showTicks: false,
      step: 0.05,
      thumbLabel: false,
      value: 0,
      vertical: true,
      isIE:
        false ||
        !!window.document["documentMode"] ||
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      data: [],
      arcBgGenerator: d3
        .arc()
        .innerRadius(d => d["data"]["innerRadius"])
        .outerRadius(d => d["data"]["outerRadius"])
        .startAngle(0)
        .endAngle(Math.PI * 2),
      arcGenerator: d3
        .arc()
        .innerRadius(d => d["data"]["innerRadius"] - 1)
        .outerRadius(d => d["data"]["outerRadius"] + 1)
        .startAngle(d => d["data"]["startAngle"])
        .endAngle(d => d["data"]["currentAngle"])
        .cornerRadius(25),
      arcGeneratorFull: d3
        .arc()
        .innerRadius(d => d["data"]["innerRadius"] - 1)
        .outerRadius(d => d["data"]["outerRadius"] + 1)
        .startAngle(d => d["data"]["startAngle"])
        .endAngle(d => d["data"]["endAngle"])
        .cornerRadius(25),
      arcEmpty: d3
        .arc()
        .innerRadius(d => d["data"]["innerRadius"])
        .outerRadius(d => d["data"]["outerRadius"])
        .startAngle(d => d["data"]["startAngle"])
        .endAngle(d => d["data"]["startAngle"])
        .cornerRadius(25)
    };
  },
  props: {
    jldata: {
      type: Array
    },
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 700
    },
    type: {
      type: String,
      default: "1"
    },
    radio: {
      type: Number,
      default: 1
    },
    circleSize: {
      type: Number,
      default: 50
    },
    clickvalue: {
      type: Number,
      default: 0.75
    }
  },
  watch: {
    jldata(newVal) {
      this.data = newVal;
      if (this.data.length > 0) {
        this.initChart();
      }
    },
    type(newVal) {
      this.currType = newVal;
    },
    clickvalue(newVal) {
      this.zoomValue = newVal;
      this.changZoom(newVal);
    }
  },
  created() {},
  mounted() {
    this.loading = false;
    this.data = this.jldata;
    this.currType = this.type;
    if (this.data.length > 0) {
      this.initChart();
    }
  },
  destroyed() {
    // d3.selectAll("svg > *").remove();
    // if (this.chartElement.nativeElement) {
    //   d3.select(this.chartElement.nativeElement).html(null);
    // }
    // this.rankingBroadcastSubscription.unsubscribe();
  },
  methods: {
    ...mapActions("home", ["setCompany"]),
    async initChart() {
      await this.initScales();
      let _this = this;
      d3.select(".circle_box").html(null);

      this.data.forEach(d => {
        // console.log(d);
        // d["feeling"] = d["blog_count"] + d["news_count"];
        d["feeling"] = d["all_count"];
        // console.log(1);
        d["innerRadius"] = this.scaleInnerRadius(d["feeling"]);
        // d["innerRadius"] = this.scaleInnerRadius = d["feeling"];
        // console.log(2);

        d["outerRadius"] = this.scaleRadius(d["feeling"]);
        // d["outerRadius"] = this.scaleRadius = d["feeling"];
        d["innerRadiusOld"] = d["innerRadius"];
        d["outerRadiusOld"] = d["outerRadius"];

        d["startAngle"] = this.randomAngle();
        d["endAngle"] = Math.PI * 2 + d["startAngle"];
        d["currentAngle"] = d["startAngle"] * 1.1;
      });
      // return;
      this.svg = d3
        .select(".circle_box")
        .append("object")
        .classed("svgWrapper", true)
        .append("svg")
        .attr("viewBox", "0 0 " + this.viewBoxX + " " + this.viewBoxY)
        // .style("width", this.viewBoxX + "px")
        // .style("height", this.viewBoxY + "px")
        .attr("preserveAspectRatio", "xMidYMid meet");

      // console.log(2);
      // (this.svgWidth = this.chartElement.nativeElement.parentNode.parentNode.offsetWidth),
      // (this.svgHeight = this.chartElement.nativeElement.parentNode.parentNode.offsetHeight);
      this.svgWidth = 0;
      this.svgHeight = 0;
      this.svgDefs();
      this.zoomLayer = this.svg.append("g");
      // console.log(this.zoomLayer)
      const root = d3.hierarchy({ children: this.data }).sum(d => {
        return d["innerRadius"];
      });

      const pack = d3
        .pack()
        // .size([this.svgWidth, this.svgHeight])
        .size([1600, 1600])
        .padding(this.padding)
        .radius(d => {
          return d["data"]["outerRadius"];
        });
      this.data = pack(root).leaves();
      // console.log("root", this.data);
      // this.applySimulation();
      // console.log(3)
      // console.log(d3)
      const bgArcs = this.zoomLayer
        .selectAll("g")
        // .style("width", this.viewBoxX + "px")
        // .style("height", this.viewBoxY + "px")
        .data(this.data)
        .enter()
        .append("g")
        .attr("class", "entityCircle")
        .attr("transform", function(d) {
          var k = "translate(" + d["x"] + "," + d["y"] + ")";
          return k;
        })
        .append("path")
        .classed("bgArc", true)
        // .style("filter", "url(" + window.location.href + "#drop-shadow)")
        .style("filter", () => {
          return this.f_color9;
        })
        .attr("d", this.arcBgGenerator);
      // console.log(4);
      let temp = this.zoomLayer.selectAll("g");
      // console.log('temp',temp)
      this.zoomLayer
        .selectAll("g")
        .append("path")
        .classed("filled", true)
        // .style("fill", "url(" + window.location.href + "#mainGradient)")
        .style("fill", d => {
          // console.log(d)
          if (_this.currType == "1") {
            return "url(" + window.location.href + "#mainGradient)";
          } else if (_this.currType == "2") {
            // console.log('test2')

            switch (d.data.sentimentLevel) {
              case "very-negative":
                return _this.f_color1;
              case "negative":
                return _this.f_color2;
              case "neutral":
                return _this.f_color3;
              case "positive":
                return _this.f_color4;
              case "very-positive":
                return _this.f_color5;
              default:
                return "url(" + window.location.href + "#mainGradient)";
            }
          } else if (_this.currType == "3") {
            // console.log('test3')

            if (d.data.watch > 0) {
              return _this.f_type2color4;
            } else if (
              d.data.watch == 0 ||
              d.data.watch == null ||
              d.data.watch == "null"
            ) {
              return _this.f_type2color3;
            } else if (d.data.watch < 0) {
              return _this.f_type2color2;
            } else {
              return "url(" + window.location.href + "#mainGradient)";
            }
          }
        })
        .attr("d", this.arcEmpty)
        .transition()
        .duration(2000)
        .attrTween("d", this.arcTween());
      // console.log(5)

      // Circle inside with the name over. Allows to have a mouse hover inside;
      this.zoomLayer
        .selectAll(".entityCircle")
        .on("mouseover", (d, i, nodes) => {
          // console.log("mouseover");
          // return;
          var parent = d3.select(nodes[i]);
          d["data"]["innerRadius"] = this.zoomHoverRadius;
          d["data"]["outerRadius"] = this.zoomHoverRadius + 20;

          parent.raise();
          parent.selectAll("path").interrupt();

          parent.select("textDetails").style("display", "block");

          parent
            .select(".bgArc")
            .transition()
            .duration(300)
            .attr("d", this.arcBgGenerator);

          parent
            .select(".filled")
            .transition()
            .duration(300)
            .attr("d", this.arcGeneratorFull);

          if (this.isIE || this.isSafari) {
            d3.select(nodes[i])
              .select(".circleDescr")
              .interrupt()
              .style("fill", "url(" + window.location.href + "#descrBg)");

            d3.select(nodes[i])
              .select(".circleDescr")
              .transition()
              .duration(300)
              .attr("r", d => this.zoomHoverRadius);
          } else {
            d3.select(nodes[i])
              .select(".circleDescr")
              .transition()
              .duration(300)
              .attr("r", d => this.zoomHoverRadius)
              .style("fill", "url(#descrBg)");
          }

          parent
            .select("text")
            .transition()
            .duration(200)
            .attr("dy", "-3.5em")
            .style("font-size", "5rem");

          parent
            .select(".textDetails")
            .style("display", "block")
            .transition()
            .delay(200)
            .duration(200)
            .style("opacity", "1");

          // if (this.isTrending) {
          // if (this.currType == "1") {
          //   console.log("type", 1);
          //   parent
          //     .select(".trendPerc")
          //     .classed("color_bearish", d["data"]["totalTrendPercentage"] < 0)
          //     .classed("color_bullish", d["data"]["totalTrendPercentage"] >= 0)
          //     .transition()
          //     .delay(200)
          //     .duration(2000)
          //     .text(d["totalTrendPercentage"])
          //     .tween("text", (d, i, nodes) =>
          //       this.tweenTextPercent(
          //         d,
          //         i,
          //         nodes,
          //         d["data"]["totalTrendPercentage"]
          //       )
          //     );

          //   parent
          //     .select(".publicationsCount")
          //     .transition()
          //     .delay(200)
          //     .duration(2000)
          //     .tween("text", (d, i, nodes) =>
          //       this.tweenTextNumber(
          //         d,
          //         i,
          //         nodes,
          //         d["data"]["news_count"] + d["data"]["blog_count"]
          //       )
          //     );
          // } else
          if (this.currType == "1") {
            // console.log("type", 1);
            let color =
              "linear-gradient(to right, " +
              this.color1 +
              " 40%, " +
              this.color2 +
              " 60%)";
            parent
              .select(".beatsCount")
              .style("background", color)
              .style("background-clip", "text")
              .style("-webkit-background-clip", "text")
              .style("-webkit-text-fill-color", "transparent")
              .transition()
              .delay(200)
              .duration(1500)
              // .text(d["data"]["blog_count"] + d["data"]["news_count"])
              .text(d["data"]["all_count"])
              .tween("text", (d, i, nodes) => {
                return this.tweenTextNumber(
                  d,
                  i,
                  nodes,
                  // d["data"]["blog_count"] + d["data"]["news_count"]
                  d["data"]["all_count"]
                );
              });
          } else if (this.currType == "2") {
            // console.log("type", 2);
            let color = this.f_color6;

            parent
              .select(".beatsCount")
              .style("background", color)
              .style("background-clip", "text")
              .style("-webkit-background-clip", "text")
              .style("-webkit-text-fill-color", "transparent")
              .transition()
              .delay(200)
              .duration(1500)
              // .text(d["data"]["blog_count"] + d["data"]["news_count"])
              .text(d["data"]["all_count"])
              .tween("text", (d, i, nodes) =>
                this.tweenTextNumber(
                  d,
                  i,
                  nodes,
                  // d["data"]["blog_count"] + d["data"]["news_count"]
                  d["data"]["all_count"]
                )
              );
          }

          parent
            .select(".newsCount")
            .transition()
            .delay(200)
            .duration(3000)
            .tween("text", (d, i, nodes) =>
              this.tweenTextNumberReduce(d, i, nodes, d["data"]["news_count"])
            );

          parent
            .select(".blogCount")
            .transition()
            .delay(200)
            .duration(3000)
            .tween("text", (d, i, nodes) =>
              this.tweenTextNumberReduce(d, i, nodes, d["data"]["blog_count"])
            );
        })
        .on("click", (d, i, nodes) => {
          this.tooltipData = d.data;
          this.scan();
          var parent = d3.select(nodes[i]);
          d["data"]["innerRadius"] = d["data"]["innerRadiusOld"];
          d["data"]["outerRadius"] = d["data"]["outerRadiusOld"];

          this.zoomLayer.selectAll("g").style("filter", "none");
          parent
            .select(".bgArc")
            .transition()
            .duration(300)
            .attr("d", this.arcBgGenerator);

          parent
            .select(".filled")
            .transition()
            .duration(300)
            .attr("d", this.arcGeneratorFull);

          d3.selectAll(".circleDescr").classed("activeCircle", false);

          d3.select(nodes[i])
            .select(".circleDescr")
            .classed("activeCircle", true)

            .interrupt()
            // .style("fill", "transparent");
            .style("fill", this.f_color7);
          // .style("fill", "url(#descrBg)")

          d3.select(nodes[i])
            .select(".circleDescr")
            .transition()
            .duration(300)
            .attr("r", d => d["data"]["innerRadius"]);
          parent
            .select(".textDetails")
            .transition()
            .style("opacity", "0");

          parent
            .select("text")
            .transition()
            .duration(200)
            .style("font-size", d => {
              return this.scaleFont(d["data"]["feeling"]) + "em";
            })
            .attr("dy", ".35em");

          // d3.select(nodes[i]).moveToFront();
          // d3.select(nodes[i])
          //   .on("mouseover", null)
          //   .on("mouseout", null)
          //   .select(".circleDescr")
          //   .transition()
          //   .ease(d3.easeExp)
          //   .duration(800)
          //   .attr("r", this.viewBoxX * 2)
          //   .on("start", () => {
          //     setTimeout(() => {
          //       console.log("clicked !");
          //     }, 600);
          //   });

          // this.clickMessageOpen = true;

          // setTimeout(() => {
          //   this.clickMessageOpen = false;
          // }, 3000);
        })
        .on("mouseout", (d, i, nodes) => {
          // console.log("mouseout")
          // return
          var parent = d3.select(nodes[i]);
          d["data"]["innerRadius"] = d["data"]["innerRadiusOld"];
          d["data"]["outerRadius"] = d["data"]["outerRadiusOld"];

          this.zoomLayer.selectAll("g").style("filter", "none");
          parent
            .select(".bgArc")
            .transition()
            .duration(300)
            .attr("d", this.arcBgGenerator);

          parent
            .select(".filled")
            .transition()
            .duration(300)
            .attr("d", this.arcGeneratorFull);

          d3.select(nodes[i])
            .select(".circleDescr")
            .interrupt()
            // .style("fill", "transparent");
            .style("fill", this.f_color8);
          // .style("fill", "url(#descrBg)");

          d3.select(nodes[i])
            .select(".circleDescr")
            .transition()
            .duration(300)
            .attr("r", d => d["data"]["innerRadius"]);
          parent
            .select(".textDetails")
            .transition()
            .style("opacity", "0");

          parent
            .select("text")
            .transition()
            .duration(200)
            .style("font-size", d => {
              return this.scaleFont(d["data"]["feeling"]) + "em";
            })
            .attr("dy", ".35em");
        });
      this.zoomLayer
        .selectAll("g")
        .append("circle")
        .classed("circleDescr", true)
        .on("keypress", (d, i, nodes) => {
          // console.log("keypress");
          // if (d3.event.keyCode === 32 || d3.event.keyCode === 13) {
          //   this.simulateClick(nodes[i].parentNode);
          // }
        })
        .attr("tabindex", "1")
        .attr("r", d => {
          return d["data"]["innerRadius"];
        });

      this.zoomLayer
        .selectAll("g")
        .style("cursor", "pointer")
        .append("text")
        .attr("class", "color_base noselect slidingText")
        .style("font-weight", "bold")
        .style("text-style", "Quicksand")
        .style("letter-spacing", "3px")
        .style("text-anchor", "middle")
        // .style("color", "#333")
        .text(function(d) {
          // return d["data"]["entity"]["symbol"];
          // return d["data"]["name"];
          return d["data"]["symbol"];
        })
        .style("font-size", d => {
          return this.scaleFont(d["data"]["feeling"]) + "em";
        })
        .attr("dy", ".35em")
        .call(d => ellipsis(d));

      var dx = this.scaleInnerRadiusMax * 2;
      var side =
        2 *
        (this.zoomHoverRadius +
          (this.scaleOuterRadiusMax - this.scaleInnerRadiusMax));
      // console.log(this.zoomHoverRadius);
      // console.log(this.scaleOuterRadiusMax);
      // console.log(this.scaleInnerRadiusMax);
      // console.log("side", side);
      var textGroup = this.zoomLayer
        .selectAll("g")
        .style("cursor", "pointer")
        .append("g")
        .style("opacity", "0")
        .attr("class", "textDetails")
        .attr("transform", "translate(" + [-dx - 20, -dx - 20] + ")");
      if (!this.isIE) {
        var content = textGroup
          .append("foreignObject")
          .attr("width", side)
          .attr("height", side)
          .append("xhtml:body")
          .attr("class", "detailsContent");

        content
          .append("xhtml:div")
          .attr("class", "contentTitle")
          .html(d => {
            // return d["data"]["entity"]["name"]
            return d["data"]["name"];
            // return d["data"]["symbol"];
          });

        var beatsCountWrapper = content
          .append("xhtml:div")
          .attr("class", "beatsCountWrapper");

        if (this.isTrending) {
          // beatsCountWrapper
          //   .append("xhtml:div")
          //   .attr("class", "trendPerc")
          //   .style("text-anchor", "middle")
          //   .style("font-size", "8rem")
          //   .text("");
          // beatsCountWrapper
          //   .append("xhtml:div")
          //   .style("font-size", "2.5rem")
          //   .attr("class", "beatsSub")
          //   .classed("color_n1", true)
          //   .text(this.getTranslation("trendaverage"));
          // content
          //   .append("xhtml:div")
          //   .attr("class", "beatsSplit")
          //   .html(
          //     '<div style="display: table;margin-left:10%; margin-right:15%; table-layout:fixed ; vertical-align: middle;width: 70%; font-size:4.5rem;">' +
          //       '<span class="publicationsCount"></span></div>' +
          //       '<div style="display: table;margin-left:10%; margin-right:10%; table-layout:fixed ; vertical-align: middle;width: 70%; font-size:2.5rem;"><span class="publicationsCount color_n1">' +
          //       this.getTranslation("publications") +
          //       // "相关文本" +
          //       "</span>" +
          //       "</div>"
          //   );
        } else {
          beatsCountWrapper
            .append("xhtml:div")
            .attr("class", "beatsCount")
            .style("text-anchor", "middle")
            .style("font-size", "8rem")
            .text("");

          beatsCountWrapper
            .append("xhtml:div")
            .style("font-size", "3.5rem")
            .attr("class", "beatsSub")
            .classed("color_n1", true)
            // .text(this.getTranslation("publications"));
            .text("相关文本");

          //Using em here to have a size relative to the circle
          content
            .append("xhtml:div")
            .attr("class", "beatsSplit")
            .style("font-size", "3em")

            .html(
              '<div class="split_box">' +
                '<div class="news_box"><span style="color : ' +
                this.color1 +
                ';" class="newsCount"></span>' +
                '<span  style="font-size: 3rem;color : ' +
                this.color1 +
                ';">' +
                // this.getTranslation("news_count") +
                "新闻媒体" +
                "</span></div>" +
                '<div class="separator"></div>' +
                '<div class="blog_box" style="text-align:center;"><span style="font-size: 3rem; color : ' +
                this.color2 +
                ';" class="blogCount"></span> ' +
                '<span style="  color : ' +
                this.color2 +
                ';">' +
                // this.getTranslation("blog_count") +
                "社交媒体" +
                "</span></div></div>"
            );
        }
      } else {
        var textGroup = textGroup.append("g").attr("class", "detailsContent");
        textGroup
          .append("text")
          .attr("y", "2em")
          .attr("x", "0")
          .style("text-anchor", "middle")
          .attr("dx", this.zoomHoverRadius)
          .attr("dy", "6em")
          .style("text-anchor", "middle")
          .style("font-size", "2.5rem")
          .attr("class", "contentTitle")
          .text(d => d["data"]["entity"]["name"])
          // .text(d => d["data"]["entity"]["symbol"])
          .call(d => {
            wrap(d, 720);
          });

        var beatsCountWrapper = textGroup
          .append("g")
          .attr("class", "beatsCountWrapper");
        if (this.isTrending) {
          // beatsCountWrapper
          //   .append("text")
          //   .attr("class", "trendPerc")
          //   .style("text-anchor", "middle")
          //   .attr("dy", this.zoomHoverRadius - 20)
          //   .attr("dx", this.zoomHoverRadius)
          //   .style("font-size", "8rem")
          //   .text(0);
          // beatsCountWrapper
          //   .append("text")
          //   .style("font-size", "2.5rem")
          //   .style("text-anchor", "middle")
          //   .attr("dy", this.zoomHoverRadius + 75)
          //   .attr("dx", this.zoomHoverRadius)
          //   .attr("class", "beatsSub")
          //   .classed("color_n1", true)
          //   .text(this.getTranslation("trendaverage"));
          // const beatSplit = textGroup
          //   .append("g")
          //   .attr("class", "beatsSplit")
          //   .style("font-size", "3rem");
          // beatSplit
          //   .append("text")
          //   .style("text-anchor", "middle")
          //   .attr("dy", this.zoomHoverRadius + 250)
          //   .attr("dx", this.zoomHoverRadius)
          //   .attr("class", "publicationsCount")
          //   .text(0);
          // beatSplit
          //   .append("text")
          //   .style("text-anchor", "middle")
          //   .classed("color_n1", true)
          //   .attr("dy", this.zoomHoverRadius + 320)
          //   .attr("dx", this.zoomHoverRadius)
          //   .text(this.getTranslation("publications"));
          // .text("相关文本");
        } else {
          beatsCountWrapper
            .append("text")
            .attr("class", "beatsCount")
            .style("text-anchor", "middle")
            .attr("dy", this.zoomHoverRadius - 20)
            .attr("dx", this.zoomHoverRadius + 20)
            // .style("fill", " url(" + window.location.href + "#mainGradient)")
            .style("font-size", "8rem")
            .text(0);

          beatsCountWrapper
            .append("text")
            .style("font-size", "3.5rem")
            .style("text-anchor", "middle")
            .attr("dy", this.zoomHoverRadius + 75)
            .attr("dx", this.zoomHoverRadius + 20)
            .attr("class", "beatsSub")
            .text(this.getTranslation("publications"));

          var beatSplit = textGroup
            .append("g")
            .attr("class", "beatsSplit")
            .style("font-size", "3rem");

          beatSplit
            .append("text")
            .style("text-anchor", "left")
            .attr("dy", this.zoomHoverRadius + 250)
            .attr("dx", this.zoomHoverRadius / 3 - 20)
            .attr("class", "newsCount")
            .style("color", this.color1)
            .style("fill", this.color1)
            .text(0);

          beatSplit
            .append("text")
            .style("text-anchor", "right")
            .attr("dy", this.zoomHoverRadius + 250)
            .attr("dx", this.zoomHoverRadius - 120)
            .style("fill", this.color1)
            .style("fill", this.color1)
            .text(this.getTranslation("news_count"));

          beatSplit
            .append("text")
            .style("text-anchor", "left")
            .style("fill", this.color2)
            .style("color", this.color2)
            .attr("dy", this.zoomHoverRadius + 250)
            .attr("dx", this.zoomHoverRadius + 40)
            .attr("class", "blogCount")
            .text(0);

          beatSplit
            .append("text")
            .style("text-anchor", "right")
            .style("fill", this.color2)
            .style("color", this.color2)
            .attr("dy", this.zoomHoverRadius + 250)
            .attr("dx", this.zoomHoverRadius + this.zoomHoverRadius / 2)
            .text(this.getTranslation("blog_count"));

          beatSplit
            .append("line")
            .attr("x1", this.zoomHoverRadius + 20)
            .attr("x2", this.zoomHoverRadius + 20)
            .attr("y1", this.zoomHoverRadius + 150)
            .attr("y2", this.zoomHoverRadius + 425)
            // .style("stroke", "white");
            .style("stroke", this.f_color9);
        }
      }
      //this.zoomLayer.attr('transform', 'translate(1000, 9000)');
      function ellipsis(text) {
        text.each(function() {
          let reduced = false;
          var text = d3.select(this);
          let textValue = text.text();
          let textLength = text.node().getComputedTextLength(),
            containerSize =
              +d3
                .select(text.node().parentNode)
                .select(".circleDescr")
                .attr("r") * 2;
          while (textLength > containerSize - 50) {
            reduced = true;
            textValue = textValue.slice(0, textValue.length - 2);
            text.text(textValue);
            textLength = text.node().getComputedTextLength();
          }
          if (reduced) {
            text.text(text.text() + "...");
          }
        });
      }
      function wrap(text, width) {
        text.each(function() {
          var text = d3.select(this),
            words = text
              .text()
              .split(/\s+/)
              .reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = text.attr("dx"),
            y = text.attr("dy"),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");

          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);
            }
          }
        });
      }

      var zoomed = () => {
        // This is the current scale value from the event
        this.zoomValue = d3.event.transform.k;
        // console.log("zoom Value", this.zoomValue);
        // console.log(d3.event.transform);
        this.zoomLayer.attr("transform", d3.event.transform);
        this.zoomLayer.attr("transform", d3.event.transform);
      };

      this.zoom = d3
        .zoom()
        .scaleExtent([this.zoomScaleMin, this.zoomScaleMax])
        .on("zoom", zoomed);

      // d3.select(this.chartElement.nativeElement).classed("hide", false);
      d3.select(".circle_box").classed("hide", false);

      d3.selection.prototype.moveToFront = function() {
        return this.each(function() {
          this.parentNode.appendChild(this);
        });
      };

      this.sliderChanged();
      // this.applySimulation();
    },

    sliderChanged() {
      if (this.data.length > 0) {
        this.svg.call(this.zoom).call(
          this.zoom.transform,
          // d3.zoomIdentity.translate("50%", "50%").scale(this.zoomValue)
          d3.zoomIdentity.translate(270, 200).scale(this.zoomValue)
        );
        // if (this.zoomValue > 0.5) {

        // } else {
        //   this.svg.call(this.zoom).call(
        //     this.zoom.transform,
        //     // d3.zoomIdentity.translate("50%", "50%").scale(this.zoomValue)
        //     d3.zoomIdentity.translate(500, 400).scale(this.zoomValue)
        //   );
        // }
      }
    },
    arcTween() {
      // console.log('arc')
      return d => {
        const interpolate = d3.interpolate(
          d["data"]["startAngle"],
          d["data"]["endAngle"]
        );

        return t => {
          d["data"]["currentAngle"] = interpolate(t);
          var arc = this.arcGenerator(d);
          return arc;
        };
      };
    },
    initScales() {
      this.scaleInnerRadius = d3
        .scaleLinear()
        .domain([
          d3.min(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          }),
          d3.max(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          })
        ])
        .range([this.scaleInnerRadiusMin, this.scaleInnerRadiusMax]);

      this.scaleRadius = d3
        .scaleLinear()
        .domain([
          d3.min(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          }),
          d3.max(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          })
        ])
        .range([this.scaleOuterRadiusMin, this.scaleOuterRadiusMax]);

      this.scaleFont = d3
        .scaleLinear()
        .domain([
          d3.min(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          }),
          d3.max(this.data, function(d) {
            // return +d["blog_count"] + +d["news_count"];
            return d["all_count"];
          })
        ])
        .range([0.8, 6]);
    },
    circleAngle(d) {
      const anglePercent =
        d["news_count"] / (d["news_count"] + d["blog_count"]);
      return Math.PI * 2 * anglePercent;
    },
    randomAngle() {
      return Math.random() * Math.PI * 2;
    },
    tweenTextNumber(d, i, nodes, number) {
      let _this = this;
      var format = d3.format(".0f");
      var formatPercent = d3.format(".0%");
      //keep a reference to 'this'
      let int = null;
      if (_this.radio == 24) {
        int = d3.interpolate(0, (number / 100).toFixed(2));
      } else {
        int = d3.interpolate(0, number);
      }
      // var int = d3.interpolate(0, number);
      return function(t) {
        //说明为top类型
        if (_this.radio == 24) {
          nodes[i].innerText = "+" + formatPercent(int(t));
          nodes[i].textContent = "+" + formatPercent(int(t));
        } else {
          nodes[i].innerText = format(int(t));
          nodes[i].textContent = format(int(t));
        }
        //use that reference in the inner function
      };
    },
    abbreviateNumber(num) {
      return Math.abs(num) > 999999
        ? Math.sign(num) * +(Math.abs(num) / 1000000).toFixed(1) + "M"
        : Math.abs(num) > 999
        ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(0) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    tweenTextNumberReduce(d, i, nodes, number) {
      var format = d3.format(".0f");
      //keep a reference to 'this'
      var int = d3.interpolate(0, number);
      return t => {
        nodes[i].innerText = this.abbreviateNumber(format(int(t)));
        nodes[i].textContent = this.abbreviateNumber(format(int(t)));
        //use that reference in the inner function
      };
    },
    tweenTextPercent(d, i, nodes, number) {
      var format = d3.format(".0f");
      number = number * 100;
      //keep a reference to 'this'
      var int = d3.interpolate(0, number);
      var prefix = number >= 0 ? "+" : "-";
      return function(t) {
        nodes[i].innerText = prefix + " " + format(int(t)) + "%";
        nodes[i].textContent = prefix + " " + format(int(t)) + "%";
        //use that reference in the inner function
      };
    },
    applySimulation() {
      var ratio = this.svgWidth / this.svgHeight;

      if (this.simulation == undefined) {
        this.simulation = d3
          .forceSimulation(this.data)
          .force("center", d3.forceCenter(300 / 2, 200 / 2))
          .force("y", d3.forceY(1).strength(0.5 * (1 / ratio)))
          .force("x", d3.forceX(1).strength(0.5 * ratio))
          .force(
            "collision",
            d3.forceCollide(d => {
              return d["data"]["outerRadius"] + 20;
            })
          );
      } else {
        this.simulation
          .force("y", d3.forceY(1).strength(0.05 * ratio))
          .force("x", d3.forceX(1).strength((0.05 * 1) / ratio))
          .nodes(this.data);

        for (var i = 0; i < 300; i++) this.simulation.tick();

        this.simulation.alpha(1).restart();
      }
      this.ticked();
      //
    },
    ticked() {
      d3.select("g")
        .selectAll(".entityCircle")
        .attr("transform", function(d) {
          var k = "translate(" + d.x + "," + d.y + ")";
          return k;
        });
    },
    svgDefs() {
      var defs = this.svg.append("defs").style("visibility", "hidden");

      var mainGradient = defs
        .append("linearGradient")
        .attr("id", "mainGradient");

      // Create the stops of the main gradient. Each stop will be assigned
      // a class to style the stop using CSS.
      mainGradient
        .append("stop")
        .attr("class", "stop-left")
        .attr("offset", "0.3")
        .attr("stop-color", this.color1);
      mainGradient
        .append("stop")
        .attr("class", "stop-right")
        .attr("offset", "1.5")
        .attr("stop-color", this.color2);

      const descrBg = defs.append("linearGradient").attr("id", "descrBg");

      descrBg
        .append("stop")
        .attr("class", "descrBg-stop")
        .attr("stop-color", this.bubbleColorBg)
        .attr("offset", "0");

      const filter = defs
        .append("filter")
        .attr("id", "drop-shadow")
        .attr("height", "140%")
        .attr("width", "80px");

      filter
        .append("feDropShadow")
        .attr("flood-opacity", "0.6")
        .attr("dx", 2)
        .attr("dy", 10)
        .attr("stdDeviation", 8);

      filter.attr("width", "180%");

      var insetShadow = defs.append("filter").attr("id", "inset-shadow");

      insetShadow
        .append("feGaussianBlur")
        .attr("stdDeviation", "1")
        .attr("flood-color", this.f_color8);

      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");
    },
    reloadData() {
      d3.select(this.chartElement.nativeElement)
        .selectAll("*")
        .remove();

      if (this.data && this.data.length > 0) {
        this.initChart();
      }
    },
    simulateClick(elem /* Must be the element, not d3 selection */) {
      let evt = document.createEvent("MouseEvents");
      evt.initMouseEvent(
        "click" /* type */,
        true /* canBubble */,
        true /* cancelable */,
        window /* view */,
        0 /* detail */,
        0 /* screenX */,
        0 /* screenY */,
        0 /* clientX */,
        0 /* clientY */,
        false /* ctrlKey */,
        false /* altKey */,
        false /* shiftKey */,
        false /* metaKey */,
        0 /* button */,
        null
      ); /* relatedTarget */
      elem.dispatchEvent(evt);
    },
    getTranslation(text) {
      // return this.translation.get(key);
      let data = {};
      var result = data[text] != undefined ? data[text] : text;
      return result;
    },
    scan() {
      let _this = this;
      this.setCompany(_this.tooltipData);
      _this.$emit("userclick", _this.tooltipData);
    },

    // 控制栏
    increase() {
      if (this.zoomValue < 1) {
        this.zoomValue += 0.005;
        this.circleSize = (this.zoomValue - 0.5) * 200;
        this.sliderChanged();
      }
      // let _this = this;
      // if (this.circleSize < 10) {
      //   this.circleSize += 2;
      // }
      // _this.chartSvg.transition().call(_this.chartZoom.scaleBy, 1.1);
    },
    decrease() {
      /**
       * 0.5 =0
       * 1= 100
       * 100*0.5= 200
       * 0.5*100
       */
      if (this.zoomValue > 0.5) {
        this.zoomValue -= 0.02;
        this.circleSize = (this.zoomValue - 0.5) * 200;
        this.sliderChanged();
      }

      // let _this = this;
      // if (this.circleSize > 0) {
      //   this.circleSize -= 2;
      // }
      // _this.chartSvg.transition().call(_this.chartZoom.scaleBy, 0.9);
    },
    changZoom(e) {
      // console.log(e);
      // this.zoomValue = e / 200 + 0.5;
      this.sliderChanged();
    },
    selectTime(e) {
      this.$emit("changeTime", e);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "./bubble.css";
.node circle {
  // stroke: "#e2349";
  // stroke-width: 2px;
}
.bubblebox {
  width: 100%;
  height: 100%;

  .color_base {
    // color: #333333 !important;
    // fill: #333333 !important;
    @include baseColor() // fill: #fff !important;
      @include fillColor();
  }

  .filled {
    // fill: url(#mainGradient);
  }

  .filled:hover {
    // fill: url(#mainGradientLight);
  }

  .circleDescr {
    // fill: transparent;
    // fill: red;
    // fill: #191919;
    @include bubblefill();
  }
  .activeCircle {
    // fill: #467fae !important ;
    @include bubbleActivefill();
  }
  object.svgWrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
  }

  object.svgWrapper svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .textDetails,
  .textDetails * {
    background: transparent;
    font-size: inherit;
    z-index: -1;
    pointer-events: none;
  }
  .textDetails {
    display: none;
  }

  .detailsContent {
    // position: absolute;
    height: 100%;
    width: 100% !important;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: visible;
    .contentTitle {
      font-size: 2.5rem;
      opacity: 0.8;
      margin-top: 11rem;
      text-align: center;
      @include baseColor();
    }
  }

  .detailsContent .beatsCountWrapper {
    position: absolute;
    width: 100%;
    top: 36%;
    .beatsSub {
      // color: #fff;
      @include baseColor();
    }
  }

  .detailsContent .contentBody {
    margin-top: 2rem;
    font-weight: bold;
  }

  .detailsContent .beatsSplit {
    width: 100%;
    position: absolute;
    bottom: 110px;
    height: 80px;
    .split_box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .news_box {
      }
      .separator {
        width: 2px;
        height: 40px;
        margin: 0 10px;
        content: " ";
        overflow: visible;
      }
      .blog_box {
        // margin-right: 90px;
      }
    }
  }

  .empty_data {
    // position: absolute;
    // height: 100%;
    // opacity: 0;
    // width: 100%;
    // display: flex;
    // align-content: center;
    // justify-items: center;
    // z-index: 1;
    // /* pointer-events: none; */
    // transition: opacity 0.2s;
  }

  .empty_data.clickMessage {
    // background: rgba(0, 0, 0, 0.6);
    // color: white;
  }
  .empty_data.show {
    // opacity: 1;
  }

  .empty_data span {
    // flex: 1 1 auto;
    // text-align: center;
    // align-self: center;
    // font-size: 1.2rem;
    // letter-spacing: 0.5rem;
  }
  #circle_chart {
    position: absolute;
    // height: 100%;
    height: 90%;
    width: 100%;
    z-index: 10;
  }
  #circle_chart,
  .sliderWrapper {
    transition: opacity 0.2s;
  }
  #circle_chart.hide {
    opacity: 0;
  }
  .sliderWrapper.show {
    opacity: 1;
  }
  .medium-break .circle_feedback,
  .small-break .circle_feedback {
    line-height: 1.2rem;
    display: block;
  }
}
</style>
