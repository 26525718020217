const state = {
  currPage: "",
  detailInfo: {},
  companyId: "",
  companyArr: [],
  emitDetailId: null,
  detailSelectNews: []
};
const actions = {
  setcurrPage({ commit }, data) {
    commit("mu_setcurrPage", data);
  },
  setDetail({ commit }, data) {
    commit("mu_setDetail", data);
  },
  setCompany({ commit }, data) {
    commit("mu_setCompany", data);
  },
  setCompanyArr({ commit }, data) {
    commit("mu_setCompanyArr", data);
  },
  setDetailId({ commit }, data) {
    console.log("vue", data);
    commit("mu_setDetailId", data);
  },
  setSelectNews({ commit }, data) {
    commit("mu_setSelectNews", data);
  }
};
const mutations = {
  mu_setcurrPage(state, data) {
    state.currPage = data;
  },
  mu_setDetail(state, data) {
    state.detailInfo = data;
  },
  mu_setCompany(state, data) {
    state.companyId = data;
  },
  mu_setCompanyArr(state, data) {
    state.companyArr = data;
  },
  mu_setDetailId(state, data) {
    // console.log("mu", data);
    state.emitDetailId = data;
  },
  mu_setSelectNews(state, data) {
    state.detailSelectNews = data;
  }
};

const getters = {
  getCurrPage(state) {
    return state.currPage;
  },
  getDetailInfo(state) {
    return state.detailInfo;
  },
  getCompanyId(state) {
    return state.companyId;
  },
  getCompanyArr(state) {
    return state.companyArr;
  },
  getDetailId(state) {
    return state.emitDetailId;
  },
  getDetailSelectNews(state) {
    return state.detailSelectNews;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
