<template>
  <div id="app">
    <router-view v-if="getAuth" />
    <div v-if="noAuth" style="color:#fff;font-size:20px;">
      <p>
        抱歉，您当前没有权限访问本产品。如需开通权限，请联系您的客户经理，或通过邮件联系我们：business@smartfindata.com
        (鑫讯科技-商务合作邮箱)。
      </p>
      <p>
        {{ errmsg }}
      </p>
    </div>
  </div>
</template>
<script>
import { getQueryString, getUrl, getCookie } from "@/utils/util";
import {
  newCheckAuth,
  checkAuth,
  addSelfStock,
  prodCheckAuth
} from "@/request/config";
export default {
  data() {
    return {
      getAuth: false,
      noAuth: false
    };
  },
  components: {},
  created() {
    let getToken = getUrl("tkn");
    let getCompany = getQueryString("brand");
    let getUserName = getQueryString("username");
    let getUserId = getQueryString("userId");
    let styleColor = getQueryString("style") || "dark";
    this.$cookie.set("userName", getUserName, { expires: "3h" });
    this.$cookie.set("userId", getUserId, { expires: "3h" });
    this.$cookie.set("brand", getCompany, { expires: "3h" });
    this.$cookie.set("tkn", getToken, { expires: "3h" });
    window.document.documentElement.setAttribute("data-theme", styleColor);

    let data = {};
    console.log(process.env.VUE_APP_SECRET);
    if (process.env.VUE_APP_SECRET == "dev") {
      this.getAuth = true;
      // data = {
      //   token: getToken,
      //   brand: getCompany || ""
      // };
      // //端口校验token
      // if (getToken == null || getToken == "") {
      //   this.noAuth = true;
      // } else {
      //   prodCheckAuth(data)
      //     .then(async res => {
      //       //403代表没有权限
      //       console.log(res)
      //       if (res.data.code == "200") {
      //         this.getAuth = true;
      //         await this.$cookie.set("userName", res.data.data.userId, {
      //           expires: "24h"
      //         });
      //          await this.$cookie.set("prodmenu", res.data.categoryAccess.buzz, {
      //           expires: "24h"
      //         });
      //       } else {
      //         this.noAuth = true;
      //         this.errmsg = res.data.msg;
      //       }
      //     })
      //     .catch(err => {
      //       this.getAuth = false;
      //     });
      // }
    } else if (process.env.VUE_APP_SECRET == "staging") {
      data = {
        token: getToken,
        username: getUserName || ""
      };
      // data = {
      //   token: getToken,
      //   brand: getCompany || "prosperomarkets"
      // };
      if (getToken == null || getToken == "") {
        this.noAuth = true;
      } else {
        newCheckAuth(data).then(res => {
          if (res.data.code == "200") {
            this.getAuth = true;
            this.$cookie.set("userId", getUserId, { expires: "24h" });
            this.$cookie.set("userName", getUserName, { expires: "24h" });
          } else {
            this.noAuth = true;
            this.errmsg = res.data.msg;
          }
        });
        // prodCheckAuth(data)
        // .then(async res => {
        //   //403代表没有权限
        //   if (res.data.code == "200") {
        //     this.getAuth = true;
        //     await this.$cookie.set("userName", res.data.data.userId, {
        //       expires: "24h"
        //     });
        //   } else {
        //     this.noAuth = true;
        //     this.errmsg = res.data.msg;
        //   }
        // })
        // .catch(err => {
        //   this.getAuth = false;
        // });
      }
    } else if (process.env.VUE_APP_SECRET == "prod") {
      data = {
        token: getToken,
        brand: getCompany || ""
      };
      //端口校验token
      if (getToken == null || getToken == "") {
        this.noAuth = true;
      } else {
        prodCheckAuth(data)
          .then(async res => {
            //403代表没有权限
            if (res.data.code == "200") {
              this.getAuth = true;
              await this.$cookie.set("userName", res.data.data.userId, {
                expires: "24h"
              });
              await this.$cookie.set("prodmenu", res.data.categoryAccess.buzz, {
                expires: "24h"
              });
            } else {
              this.noAuth = true;
              this.errmsg = res.data.msg;
            }
          })
          .catch(err => {
            this.getAuth = false;
          });
      }
    }
  },
  mounted() {
    // let theme = localStorage.getItem('theme')
    // let theme = this.$cookie.get('styleColor')
    // window.document.documentElement.setAttribute('data-theme', theme)
    // if(!theme || theme === 'dark'){
    //   // window.document.documentElement.setAttribute('data-theme', 'dark')
    //   window.document.documentElement.setAttribute('data-theme', 'bright')
    // }else{
    //   window.document.documentElement.setAttribute('data-theme', 'bright')
    // }
  }
};
</script>

<style lang="scss">
@import "./default.css";
@import "./iconfont/iconfont.css";
@import "./selficonfont/iconfont.css";
// @font-face {
//   font-family: "iconfont";
//   src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834');
//   src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834#iefix') format('embedded-opentype'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.woff?t=1545807318834') format('woff'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.ttf?t=1545807318834') format('truetype'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.svg?t=1545807318834#iconfont') format('svg');
// }
// @mixin themeBg{
//   [data-theme="dark"] & {
//    background:#222;
//   }
//   [data-theme="bright"] & {
//     background:white
//   }
// }
// @import './theme.scss';
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
}
</style>
