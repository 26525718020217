<template>
  <div class="hk">
    <el-popover
      placement="right"
      v-model="popueStatus"
      width="1200"
      trigger="click"
      class="popbox"
    >
      <ul class="pop">
        <li v-for="item in labelArr" :key="item.sectorId">
          <el-radio
            v-model="selectLabelId"
            :label="item.sectorId"
            @change="selecFunc"
            >{{ item.sectorName }}</el-radio
          >
        </li>
      </ul>
      <div class="btnbox">
        <p>
          <el-button @click="cancelSelect()">取消</el-button>
        </p>
        <p>
          <el-button type="primary" @click="confirmIndustry()">确定</el-button>
        </p>
      </div>
    </el-popover>
    <el-container :span="24">
      <!-- <el-aside :span="5">
        <div class="oper_bt" @click="selectIndustry()">选行业</div>
        <div class="selected">
          <ul>
            <li v-if="selectLabel != ''">
              {{ selectLabel }}
              <i class="el-icon-delete" @click="cancelSelect()"></i>
            </li>
          </ul>
        </div> 
      </el-aside>-->
      <el-main :span="19" class="mainview">
        <div class="main_box">
          <!-- 控制器 -->
          <div class="control">
            <div class="time">
              <div class="timeline">
                <el-radio-group v-model="radio">
                  <el-row>
                    <el-col>
                      <el-radio :label="24" :color="timelineActive"
                        >当前最热</el-radio
                      >
                    </el-col>
                  </el-row>
                  <el-row class="line"> </el-row>
                  <el-row>
                    <el-col>
                      <el-radio :label="1" :color="timelineActive"
                        >24小时</el-radio
                      >
                    </el-col>
                  </el-row>
                  <el-row class="line"> </el-row>

                  <el-row>
                    <el-col>
                      <el-radio :label="7" :color="timelineActive">
                        7天
                      </el-radio>
                    </el-col>
                  </el-row>
                  <el-row class="line"> </el-row>

                  <el-row>
                    <el-col>
                      <el-radio :label="30" :color="timelineActive">
                        30天&nbsp;
                      </el-radio>
                    </el-col>
                  </el-row>
                  <el-row class="line"> </el-row>

                  <el-row>
                    <el-col>
                      <el-radio :label="90" :color="timelineActive">
                        90天
                      </el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </div>
            <div class="zoombox">
              <div class="c add" @click="increase()">+</div>
              <div class="progress">
                <el-slider
                  v-model="circleSize"
                  vertical
                  height="160px"
                  :show-tooltip="false"
                  @change="changZoom"
                >
                </el-slider>
              </div>
              <div class="c increase" @click="decrease()">-</div>
            </div>
          </div>

          <div class="subbtn" @click="changeSubMain(true)" v-if="!showSubMain">
            <p>
              《
            </p>
          </div>
          <div class="table">
            <el-tabs v-model="activeName">
              <el-tab-pane label="新闻热度" name="first"></el-tab-pane>
              <el-tab-pane label="情感指标" name="second"></el-tab-pane>
              <el-tab-pane label="趋势指标" name="third"></el-tab-pane>
            </el-tabs>
            <div class="iconmenu" @click="changeBubble()">
              <i
                v-if="bubleShow"
                class="el-icon-s-fold"
                style="color:#0BA3F1;"
              ></i>
              <span
                v-else
                class="iconfont icon-qipaotu"
                style="color:#0BA3F1;font-size:20px;"
              ></span>
              <!-- <img style="display:inline-block;width:30px;height:30px;" v-else src="./../../public/img/chart/bubble.png" alt=""> -->
            </div>
          </div>
          <!-- <div class="main_bubble" v-if="bubbledata.length > 0"> -->
          <div class="main_bubble">
            <!-- <selfbubble
              :data="bubbledata"
              :type="typebubble"
              @userclick="selectCompany"
              @changeTime="changeTime"
              v-if="bubleShow"
            ></selfbubble> -->

            <bubblenew
              :jldata="bubbledata"
              :type="typebubble"
              :radio="radio"
              :clickvalue="zoomValue"
              @userclick="selectCompany"
              v-if="bubleShow"
            ></bubblenew>
            <mainrank
              :jldata="bubbledata"
              :type="typebubble"
              @userclick="selectCompany"
              @dataChange="changeData"
              v-else
            ></mainrank>
          </div>
          <!-- <div class="nodata" v-else>暂无数据</div> -->
        </div>

        <div class="sub_main" v-if="showSubMain">
          <div class="subbtn" @click="changeSubMain(false)">
            <span>
              》
            </span>
          </div>
          <div class="hasdata" v-if="companyInfo.name != null">
            <div class="submenu">
              <div class="menuinfo">
                <div class="company">
                  {{ companyInfo.name }}
                  <span class="num">{{ companyInfo.symbol }}</span>
                </div>
                <div class="today_data">
                  <div
                    v-if="
                      companyInfo.priceChange == 0 &&
                        companyInfo.currentClose == 0 &&
                        companyInfo.currentDate == 0
                    "
                    class="nav_nodata"
                  >
                    暂无数据
                  </div>
                  <div v-else class="nav_data">
                    <p
                      class="percent"
                      :class="{ down: companyInfo.priceChange < 0 }"
                    >
                      {{ companyInfo.priceChange }}%
                    </p>

                    <p class="currnum">{{ companyInfo.currentClose }}</p>
                    <p class="from">(数据：{{ companyInfo.currentDate }})</p>
                  </div>
                </div>
                <div class="remark">
                  舆情值{{ companyInfo.total_sentiment }}，情绪{{
                    avgPolarityFilter(companyInfo)
                  }}
                </div>
                <div class="godetail">
                  <i
                    class="el-icon-star-off star greystar"
                    @click="changeSelf(1, companyInfo)"
                    v-if="companyInfo.watch_list == 0"
                  ></i>
                  <i
                    class="el-icon-star-on star staron"
                    v-else-if="companyInfo.watch_list == 1"
                    @click="changeSelf(0, companyInfo)"
                  ></i>
                  <span class="content" @click="goDetail()">看详情 》</span>
                </div>
              </div>

              <div class="chartbox">
                <el-tabs v-model="subMenuChartActive" @tab-click="handleClick">
                  <el-tab-pane label="新闻热度" name="first">
                    <ul class="news">
                      <li class="first">
                        <jlring
                          :jldata="companyInfo"
                          :title="'文章分布'"
                          :jltip="1"
                        ></jlring>
                      </li>
                      <li>
                        <ringdata
                          :jldata="companyInfo"
                          :title="'信心指数'"
                        ></ringdata>
                      </li>
                      <li>
                        <jlring
                          :jldata="companyInfo"
                          :title="'趋势指标'"
                          :jltip="3"
                        ></jlring>
                      </li>
                    </ul>
                    <ul class="news mg_10">
                      <li class="first">
                        <!-- <arcprogress
                        :title="'新闻热度'"
                        :jldata="companyInfo"
                        :jltip="1"
                      ></arcprogress> -->
                        <gsappropress
                          :title="'新闻热度'"
                          :jldata="companyInfo"
                          :jltip="1"
                          :width="300"
                          :height="220"
                        ></gsappropress>
                      </li>
                      <li>
                        <jlrank
                          :title="'最热门讨论话题'"
                          :jldata="companyInfo"
                          :jltip="1"
                        ></jlrank>
                      </li>
                    </ul>
                    <ul class="news mg_10">
                      <li class="first">
                        <jlstackbar
                          :title="'舆情历史统计'"
                          :jldata="barData"
                          :jltip="
                            '“历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。'
                          "
                        ></jlstackbar>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="情绪表现" name="second">
                    <ul class="news">
                      <li class="first">
                        <!-- <jlmood
                          :title="'情绪表现'"
                          :jldata="companyInfo"
                          :jltip="1"
                          :width="600"
                        ></jlmood> -->
                        <circleprogress
                          :title="'情绪表现'"
                          :jldata="companyInfo"
                          :jltip="1"
                          :width="300"
                          :height="340"
                        ></circleprogress>
                      </li>
                      <li>
                        <newsubjective
                          :title="'主观性'"
                          :jldata="companyInfo"
                          :jltip="1"
                          :width="280"
                          :height="340"
                        ></newsubjective>
                      </li>
                    </ul>
                    <!-- <ul class="news mg_10">
                      <li>
                        <jlsubjective
                          :title="'客观性'"
                          :jldata="companyInfo"
                          :jltip="1"
                          :width="600"
                        ></jlsubjective>
                      </li>
                    </ul> -->
                    <ul class="news mg_10" style="margin-top:20px;">
                      <li class="first">
                        <!-- <jlconfidenceband
                        :title="'历史情绪'"
                        :jldata="barData"
                        :jltip="
                          '“历史情绪“模块显示了历史情感数据变化趋势，与该资产价格之间的关联性。'
                        "
                      ></jlconfidenceband> -->
                        <timeseriseline
                          :title="'历史情绪'"
                          :jldata="barData"
                          :jltip="1"
                        ></timeseriseline>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <!-- <el-tab-pane label="看同行" name="third">
                  <ul class="news">
                    <li>
                      <jlevalution
                        :title="'综合行为评测'"
                        :jldata="companyInfo"
                        :jltip="
                          '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                        "
                      ></jlevalution>
                    </li>
                    <li>
                      <jlmultarc
                        :title="'分数'"
                        :jldata="companyInfo"
                        :jltip="
                          '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                        "
                      ></jlmultarc>
                    </li>
                    <li>
                      <same
                        :title="'同行'"
                        :jldata="sameInfo"
                        :jltip="
                          '信心指数“模块主要用来显示“情感评分“的可靠性。通过对新闻证据数量的算法处理，表明当前”情绪指向“信任度多少。 它分为低，中和高三个级别'
                        "
                      ></same>
                    </li>
                  </ul>
                </el-tab-pane> -->
                  <el-tab-pane name="fourth">
                    <div slot="label">
                      动态消息
                      <el-dropdown :hide-on-click="false">
                        <span class="el-dropdown-link dropbox">
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>
                            <el-checkbox
                              v-model="checkAll"
                              @change="handleCheckAllChange"
                              >所有</el-checkbox
                            >
                          </el-dropdown-item>
                          <el-checkbox-group
                            v-model="checkedNews"
                            @change="handleCheckedNewsChange"
                          >
                            <el-dropdown-item
                              v-for="(item, index) in newsArray"
                              :key="item"
                              :index="index"
                            >
                              <el-checkbox :label="item.id" :key="item">
                                {{ item.name }}</el-checkbox
                              >
                            </el-dropdown-item>
                          </el-checkbox-group>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <dynamicnews
                      :jldata="newsArr"
                      :newOrgin="newsArray"
                    ></dynamicnews>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
          <div class="nodata" v-else style="color:#fff">暂无数据</div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
// import selfbubble from "./../d3-components/bubble/bubble";
import bubblenew from "./../d3-components/bubble/bubblenew";
import jlring from "./../echart_components/ring";
import ringdata from "./../echart_components/ringdata";
import mainrank from "@/achart/mainrank.vue";
// import jlconfidenceband from "@/echart_components/confidenceband";
import timeseriseline from "./../d3-components/gsap/gsap_timeseries_line";

import gsappropress from "./../d3-components/gsap/gsap_progress";
// import circleprogress from "./../d3-components/circleprogress.vue";

// import d3guage from "./../d3-components/d3_guage";
// import jlringprogress from "@/d3-components/ringprogress";
import jlrank from "@/achart/rank";
// import jltrend from "@/achart/trend";
import circleprogress from "./../d3-components/circleprogress";
// import jlmood from "@/achart/mood";
import newsubjective from "@/achart/newsubjective";
// import jldashprogress from "@/achart/dashprogress";
// import arcprogress from "./../d3-components/arc_progress";
// import arcprogress from "./../d3-components/gsap/gsap_timeseries_line";
// import jlguage from "@/echart_components/guage";
import jlstackbar from "@/echart_components/stackbar";

// import jlmultarc from "@/d3-components/mult_arc";
// import jlevalution from "@/achart/evaluation";
// import same from "@/achart/same";
import dynamicnews from "@/achart/dynamicnews";
import { base } from "@/request/base.js";
import tool from "./../utils/tools";
import {
  addSelfStock,
  delSelfStock,
  getInit,
  getNewsOrigin
} from "@/request/config";

export default {
  name: "Home",
  data() {
    return {
      bubbledata: [],
      activeName: "first",
      showSubMain: false,
      subMenuChartActive: "first",
      currTabInfo: "",
      currTime: "1",
      bigTab: "articlecount/v1/",
      companyParams: "discover/v1/",
      moodParams: "timeseries/",
      industryParams: "articlecount/v1/sectors",
      industryLabel: "articlecount/v1/sectors",
      newsParams: "newsfeed/v1/all",
      sameParams: "sectorview/",
      companyInfo: {},
      sameInfo: null,
      loading: true,
      barData: {},
      selectLabel: "", //user select industry
      selectLabelId: "",
      popueStatus: false,
      labelArr: {},
      typebubble: "1",
      drawer: false,
      bubleShow: true,
      newsArray: [
        // { id: 0, name: "金十" },
        // { id: 1, name: "IGM" },
        // { id: 2, name: "路透" },
        // { id: 3, name: "财经" }
      ],
      checkAll: true,
      checkedNews: [],
      newsArr: [], //动态新闻的list
      // 控制栏
      radio: 1,
      zoomValue: 0.75,
      circleSize: 50,
      timelineActive: "#fdca64"
    };
  },
  mixins: [tool],
  components: {
    // selfbubble,
    gsappropress,
    bubblenew,
    mainrank,
    jlring,
    ringdata,
    timeseriseline,
    circleprogress,
    // jltrend,
    // jlconfidenceband,
    // jlringprogress,
    // jldashprogress,
    // arcprogress,
    jlrank,
    // newcircle,
    // jlmood,
    // jlsubjective,
    newsubjective,
    // jlguage,
    // d3guage,
    jlstackbar,
    // jlmultarc,
    // jlevalution,
    // same,
    dynamicnews
  },
  computed: {
    ...mapGetters("home", [
      "getCurrPage",
      "getCompanyId",
      "getDetailId",
      "getDetailSelectNews"
    ])
  },
  watch: {
    getCurrPage() {
      // console.log("页面切换了", newVal);
      this.initData();
    },
    activeName(newVal) {
      switch (newVal) {
        case "first":
          this.bigTab = "articlecount/v1/";
          this.typebubble = "1";

          break;
        case "second":
          this.bigTab = "sentiment/v1/";
          this.typebubble = "2";

          break;
        case "third":
          this.bigTab = "analystview/v1/";
          this.typebubble = "3";

          break;
      }
      this.initData();
    },
    showSubMain(newVal) {
      if (newVal) {
        console.log("1");
      }
    },
    jltip: function(newVal) {
      this.tips = newVal;
    },
    selectLabelId(newVal) {
      console.log("watch", newVal);
    },
    getCompanyId(newVal) {
      this.getCompanyData(newVal);
    },
    // 动态消息用户操作
    // checkedNews(newVal) {
    //   console.log('new',newVal)
    //   let _this = this;
    //   let checkedCount = this.checkedNews.length;
    //   this.newsArr = [];
    //   checkedCount == this.newsArray.length
    //     ? (this.checkAll = true)
    //     : (this.checkAll = false);

    //   if (checkedCount > 0) {
    //     if (this.checkAll) {
    //       this.newsParams = "newsfeed/v1/all";
    //       this.getNews();
    //       return;
    //     } else{
    //       _this.checkedNews.map(i=>{
    //         console.log(i)
    //         _this.newsArr.map(j =>{
    //           console.log(j)
    //           if(i ==j.id){
    //             _this.newsParams =`newsfeed/v1/${j.urlName}`
    //             _this.getNews();

    //           }
    //         })
    //       })
    //     }
    //     // if (this.checkedNews.includes(0)) {
    //     //   this.newsParams = "newsfeed/v1/jinten";
    //     //   this.getNews();
    //     //   return;
    //     // }
    //     // if (this.checkedNews.includes(1)) {
    //     //   this.newsParams = "newsfeed/v1/informa";
    //     //   this.getNews();
    //     //   return;
    //     // }
    //     // if(this.checkedNews.includes(2)){
    //     //   this.newsParams ="/newsfeed/v1/reuters"
    //     //   this.getNews();
    //     //   return;
    //     // }
    //     // if(this.checkedNews.includes(3)){
    //     //   this.newsParams ="/newsfeed/v1/fx168"
    //     //   this.getNews();
    //     //   return;
    //     // }
    //   }
    // },
    radio(newVal) {
      console.log("bubble", newVal);
      // this.$emit("changeTime", newVal);
      this.changeTime(newVal);
    },
    getDetailSelectNews(newVal) {
      console.log("hkwatch", newVal);
    }
  },
  mounted() {
    this.initData();
    /**
     * 获取新闻源
     */
    getNewsOrigin().then(res => {
      console.log(res);
      this.newsArray = res.data.newsFeed;
      this.newsArray.map(item => {
        this.checkedNews.push(item.id);
      });
    });
  },
  methods: {
    ...mapActions("home", ["setDetail", "setCompanyArr", "setCompany"]),
    //动态消息交互问题
    handleCheckAllChange(val) {
      console.log("全选", val);
      if (val) {
        let temp = [];
        this.newsArray.map(item => {
          temp.push(item.id);
        });
        this.checkedNews = temp;
        //发起请求
        this.newsParams = "newsfeed/v1/all";
        this.getNews();
      } else {
        this.newsArr = [];
        this.checkedNews = [];
      }
    },
    handleCheckedNewsChange(value) {
      console.log(this.checkedNews);
      let _this = this;
      let checkedCount = this.checkedNews.length;
      this.newsArr = [];
      checkedCount == this.newsArray.length
        ? (this.checkAll = true)
        : (this.checkAll = false);

      if (checkedCount > 0) {
        if (this.checkAll) {
          this.newsParams = "newsfeed/v1/all";
          this.getNews();
          return;
        } else {
          _this.checkedNews.map(i => {
            _this.newsArray.map(j => {
              if (i == j.id) {
                _this.newsParams = `newsfeed/v1/${j.urlName}`;
                _this.getNews();
              }
            });
          });
        }
      }
    },
    changeSelf(e, item) {
      let localUserId = this.$cookie.get("userId");
      let localUserName = this.$cookie.get("userName");
      if (e) {
        addSelfStock({
          username: localUserName,
          userId: localUserId,
          entityId: item.entityId
        }).then(res => {
          if (res.data.code) {
            this.$message({
              showClose: true,
              message: "添加成功",
              type: "success"
            });
            this.$emit("dataChange", true);
          }
        });
      } else {
        delSelfStock({
          username: localUserName,
          userId: localUserId,
          entityId: item.entityId
        }).then(res => {
          console.log(res.data.code);
          if (res.data.code) {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success"
            });
            this.$emit("dataChange", true);
          }
        });
      }
    },
    changeBubble() {
      this.bubleShow = !this.bubleShow;
      if (!this.bubleShow) {
        this.changeData();
      } else {
        this.initData();
      }
    },
    changeData(e) {
      let param = "";
      param = `${this.bigTab}${this.currTime}/${
        base[this.getCurrPage["icon"]]
      }`;
      getInit(param).then(res => {
        // console.log(res.data.articleCounts);
        switch (this.bigTab) {
          case "articlecount/v1/":
            this.bubbledata = res.data.articleCounts;
            break;
          case "sentiment/v1/":
            this.bubbledata = res.data.sentimentSummary;
            break;
          case "analystview/v1/":
            this.bubbledata = res.data.analystViewSummary;

            break;
        }
      });
    },
    selectIndustry() {
      this.loading = false;
      let _this = this;
      let param = `${this.industryLabel}`;

      getInit(param).then(res => {
        _this.popueStatus = true;
        _this.labelArr = res.data.sectorIdMapping;
      });
    },
    selecFunc(e) {
      console.log(e);
    },
    cancelSelect() {
      this.popueStatus = false;
      this.selectLabel = "";
      this.selectLabelId = "";
      this.initData();
    },
    confirmIndustry() {
      let _this = this;
      this.popueStatus = false;
      this.labelArr.map(item => {
        if (item.sectorId == _this.selectLabelId) {
          _this.selectLabel = item.sectorName;
        }
      });

      this.initData();
    },
    goDetail() {
      let _this = this;
      _this.setDetail({
        companyInfo: _this.companyInfo,
        barData: _this.barData,
        sameInfo: _this.sameInfo,
        newsArr: _this.newsArr
      });
      this.$router.push({
        path: "/detail"
      });
    },
    avgPolarityFilter(e) {
      if (e.total_sentimentLabel == "very-negative") {
        return "非常悲观";
      } else if (e.total_sentimentLabel == "negative") {
        return "悲观";
      } else if (e.total_sentimentLabel == "neutral") {
        return "中立";
      } else if (e.total_sentimentLabel == "positive") {
        return "乐观";
      } else if (e.total_sentimentLabel == "very-positive") {
        return "非常乐观";
      }
    },
    initData() {
      let param = "";
      if (this.selectLabelId != "") {
        param = `${this.bigTab}${this.currTime}/${
          base[this.getCurrPage["icon"]]
        }/${this.selectLabelId}`;
      } else {
        param = `${this.bigTab}${this.currTime}/${
          base[this.getCurrPage["icon"]]
        }`;
      }
      // console.log("temp", param);
      getInit(param).then(res => {
        // console.log(res.data.articleCounts);
        switch (this.bigTab) {
          case "articlecount/v1/":
            this.bubbledata = res.data.articleCounts;
            break;
          case "sentiment/v1/":
            this.bubbledata = res.data.sentimentSummary;
            break;
          case "analystview/v1/":
            this.bubbledata = res.data.analystViewSummary;
            break;
        }

        this.setCompanyArr(this.bubbledata);
      });
    },

    getCompanyData(data) {
      let _this = this;
      this.loading = false;
      let param = "";
      if (data.g) {
        param = `${this.companyParams}${data.g}/${data.entity_id}`;
      } else {
        param = `${this.companyParams}${data.entity_id}`;
      }

      this.getBarData(data);
      // this.getSameData(data);

      getInit(param).then(res => {
        try {
          _this.companyInfo = res.data.entity;
          _this.getNews();
        } catch (err) {}

        _this.showSubMain = true;
      });
    },
    getNews() {
      this.loading = true;
      let _this = this;

      let param = `${this.newsParams}/${this.companyInfo.entityId}`;
      // let param = `${this.newsParams}`;
      _this.newsArr = [];
      getInit(param).then(res => {
        this.loading = false;
        if (res.data.newsFeed.length > 0) {
          _this.newsArr = res.data.newsFeed;
          _this.newsArray.map(i => {
            _this.newsArr.map(j => {
              if (i.provider == j.provider) {
                j.logoUrl = i.logoUrl;
              }
            });
          });
        }
      });
    },
    // 看同行的接口
    getSameData(data) {
      this.loading = false;
      let _this = this;
      let param = `${this.companyParams}${this.sameParams}${data.entity_id}`;

      getInit(param).then(res => {
        _this.sameInfo = res.data.sectorView;
      });
    },
    getIndustryData() {
      this.loading = false;
      // let _this = this;

      let param = `${this.bigTab}${this.currTime}/${
        base[this.getCurrPage["icon"]]
      }/${this.selectLabelId}`;

      getInit(param).then(res => {
        console.log(res);
      });
    },
    getBarData(data) {
      let _this = this;
      let param = `${this.companyParams}${this.moodParams}${data.entity_id}/30`;
      getInit(param).then(res => {
        // _this.companyInfo = res.data.entity;
        _this.barData = res.data.newsAndSentimentHistory;
      });
    },

    changeTime(data) {
      console.log("changeTime", data);
      if (data == 24) {
        this.currTime = "top";
      } else {
        this.currTime = data;
      }
      console.log(this.currTime);
      this.initData();
    },
    // 控制栏
    increase() {
      if (this.zoomValue < 1) {
        this.zoomValue += 0.005;
        this.circleSize = (this.zoomValue - 0.5) * 200;
      }
    },
    decrease() {
      /**
       * 0.5 =0
       * 1= 100
       * 100*0.5= 200
       * 0.5*100
       */
      if (this.zoomValue > 0.5) {
        this.zoomValue -= 0.02;
        this.circleSize = (this.zoomValue - 0.5) * 200;
      }
    },
    changZoom(e) {
      this.zoomValue = e / 200 + 0.5;
    },
    selectCompany(data) {
      this.getCompanyData(data);
    },
    changeSubMain(a) {
      if (a) {
        try {
          Promise.all([
            this.getCompanyData(this.bubbledata[0]),
            this.setCompany(this.bubbledata[0])
          ]).then(() => {
            this.showSubMain = true;
          });
        } catch (err) {
          this.showSubMain = true;
        }

        // this.getCompanyData(this.bubbledata[0]);
        // this.setCompany(this.bubbledata[0]);
      } else {
        this.loading = false;
        this.showSubMain = false;
        this.companyInfo = {};
      }
    }
  }
};
</script>
<style lang="scss">
// .iconfont {
//   font-family: "iconfont" !important;
//   font-size: 16px;
//   font-style: normal;
//   color:#0BA3F1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
.hk {
  width: 100%;
  height: 100%;
  .popbox {
    position: absolute;
    left: 20px;
    top: 60px;

    .pop {
      width: 1200px;
      // min-height: 300px;
      height: 800px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 260px;
      }
    }
    .btnbox {
      display: flex;
      justify-content: center;
      p {
        margin-left: 20px;
      }
    }
  }
  .el-aside {
    width: 200px !important;
    .oper_bt {
      background: #0ba3f1;
      color: #fff;
      border-radius: 10px;
      width: 72px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
    }
    .selected {
      ul > li {
        margin-top: 20px;
        color: #b4b4b4;
      }
    }
  }
  .mainview {
    margin-top: 20px !important;
  }
  .el-main {
    display: flex;

    .main_box {
      flex: 6;
      position: relative;

      .control {
        position: absolute;
        right: 0px;
        top: 60px;
        background: transparent;
        z-index: 99;
        .time {
          // padding-right: 20px;
          z-index: 99;

          .timeline {
            // width: 120px;
            .el-radio,
            .el-radio--medium.is-bordered .el-radio__label {
              font-size: 14px;
              display: flex;
              flex-direction: row-reverse;
            }
            .el-timeline-item {
              .el-timeline-item__content {
                color: #fff !important;
              }
            }
            .el-col {
              height: 20px !important;
              .el-radio__label {
                font-size: 18px !important;
                display: inline-block !important;
                width: 80px !important;
              }
              .el-radio__inner {
                background-color: #5b5b5b !important;
                border-color: #5b5b5b !important;

                // @include tabBgColor();
                // @include tabBorderColor();
              }
              .el-radio__input.is-checked + .el-radio__label {
                // color: #fdca64 !important;
                @include tabBgLineColor();
              }

              .el-radio__input.is-checked .el-radio__inner {
                // border-color: #fdca64 !important;
                // background: #fdca64 !important;
                @include tabBorderColor();
                @include tabBgLineColor();
              }
            }
            .line {
              height: 14px;
              border-right: 1px solid rgb(139, 128, 128);
              margin-right: 6px;
              margin-bottom: 6px;
              .content {
                color: #b4b4b4 !important;
              }
            }
          }
        }
        .zoombox {
          margin-right: 20px;
          .c {
            font-size: 20px;
            color: #fff;
            cursor: pointer;
            text-align: right;
            padding-right: 12px;
          }
          .add {
          }
          .progress {
            height: 160px;
            width: 150px;
            display: flex;
            justify-content: flex-end;
            // margin-right: 20px;
            .el-slider__runway {
              background-color: #5b5b5b !important;
            }
          }
          .increase {
            font-size: 30px;
          }
        }
      }

      .subbtn {
        position: absolute;
        right: 0;
        top: 60px;

        // padding: 5px;
        padding: 10px 0px 10px 10px;

        text-align: center;

        z-index: 999;
        width: 15px;
        height: 35px;
        line-height: 35px;
        // border-top-left-radius: 5px;
        // border-bottom-left-radius: 5px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        p {
          background: #0ba3f1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        // border: 10px solid transparent;
        // border-color: transparent;
      }
      .subbtn:hover {
        cursor: pointer;
      }
      .table {
        display: flex;

        justify-content: flex-end;

        align-items: center;
        border-bottom: 2px solid #535353;

        .iconmenu {
          margin-right: 20px;
          margin-left: 40px;
          color: #fff;
          cursor: pointer;
       
        }
        .iconmenu:hover {
          cursor: pointer;
        }
        .el-tabs__active-bar {
          // background-color: #fdca64 !important;
          @include tabBgColor();
        }
        .el-tabs__item {
          font-size: 22px !important;
          // color: #bababa !important;
          @include fontColor3();
        }
        .is-active {
          // color: #fdca64 !important;
          @include tabBgLineColor();
        }
        .el-tabs__item:hover {
          // color: #fdca64 !important;
          @include tabBgLineColor();
        }
        .el-tabs__nav-wrap::after {
          height: 0 !important;
        }
      
      }
      .main_bubble{
        // width:70%;
        // margin:0 auto;
      }
      .main_bubble {
        // width:70%;
        // margin:0 auto;
      }
      .nodata {
        font-size: 20px;
        color: #fff;
      }
    }
    .sub_main {
      // width: 660px;
      flex: 3;
      position: relative;
      border-left: 1px solid #3a3a3a;
      .subbtn {
        position: absolute;
        left: 0;
        top: 60px;
        background: #0ba3f1;
        color: #fff;
        font-size: 12px;

        width: 15px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 999;
        cursor: pointer;
      }
      .subbtn:hover {
        cursor: pointer;
      }
      .submenu {
        .menuinfo {
          height: 118px;
          text-align: left;
          margin-left: 30px;
          position: relative;
          .company {
            font-size: 24px;
            // color: #fff;
            height: 40px;
            line-height: 40px;
            @include fontColor();

            .num {
              font-size: 20px;
              margin-left: 20px;
            }
          }
          .today_data {
            height: 40px;
            display: flex;
            .nav_nodata {
              color: #fff;
            }
            .nav_data {
              display: flex;
            }
            .percent {
              font-size: 22px;
              color: red;
            }
            .down {
              color: green;
            }
            .currnum {
              color: #fff;
              font-size: 22px;
              margin-left: 30px;
            }
            .from {
              font-size: 18px;
              margin-left: 15px;
              color: #b4b4b4;
            }
          }
          .remark {
            font-size: 22px;
            // color: #fdca64;
            @include tabBgLineColor();
          }
          .godetail {
            position: absolute;
            top: 10px;
            right: 20px;
            .star {
              margin-left: 20px;
              font-size: 20px;
              // color: red;
              color: #fff;
            }
            .greystar {
              padding-right: 4px;
            }
            .staron {
              font-size: 26px;
              color: #ff7373;
            }
            .content {
              color: #fff;
              margin-left: 20px;
              background: #0ba3f1;
              border-radius: 5px;
              padding: 4px 8px;
              font-size: 14px;
              cursor: pointer;
            }
          }
          .godetail:hover {
            cursor: pointer;
          }
        }
        .chartbox {
          width: 100%;
          .el-tabs__active-bar {
            // background-color: #fff !important;
          }
          .el-tabs__item {
            font-size: 24px !important;
            // color: #bababa !important;
            min-width: 200px !important;
            @include fontColor3();
          }
          .is-active {
            // color: #fff !important;
            @include tabFontColor();
            font-weight: 500;
          }
          .el-tabs__item:hover {
            // color: #fdca64 !important;
          }
          .el-tabs__nav-wrap::after {
            background-color: #3a3a3a !important;
          }
          .news {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              flex: 1;
              margin-left: 10px;
            }
            .first {
              margin: 0;
            }
          }
          .mg_10 {
            margin-top: 10px;
          }
          .dropbox {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: grey;
            text-align: center;
            color: #fff;
            .el-icon--right {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}
.hk::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.hk::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
  background: #535353;
}
.hk::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
  border-radius: 10px;
  // background: #ededed;
  background: black;
}
</style>
