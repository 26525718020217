import Vue from "vue";
import Vuex from "vuex";

import home from "./home";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    home: {
      namespaced: true,
      ...home
    }
  }
});

export default store;
