<template>
  <div
    class="stackbarbox"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      class="stackbar"
      :id="this.id"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
    <div class="tipbox">
      <div class="con">{{ title }}</div>
      <div class="help">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content">
            “舆情历史统计”显示该实体的市场价格与新闻量、情绪指向关联的历史数据图。
            <br />
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import chartTheme from "@/utils/chartTheme.js";

// 引入 ECharts 主模块
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");

import tools from "@/utils/tools.js";
export default {
  name: "jlconfidenceband",
  data() {
    return {
      // 刻度颜色
      axisColor: "#797979",
      // 分割线颜色
      splitLineColor: "#dcdcdc",
      chart: null,
      chartData: Array,
      loading: true,
      tips: "",
      leftY: [],
      rightY: [],
      firstData: [],
      secondData: [],
      thirdData: [],
      Xdata: []
    };
  },
  mixins: [tools],
  props: {
    // 图表区域高度
    title: {
      type: String,
      default: "历史情绪"
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 300
    },
    // 图表形状颜色, ecahrts依次选择颜色渲染
    color: {
      type: Array,
      default: function() {
        return chartTheme.ringColor;
      }
    },
    // 背景颜色
    bgColor: {
      type: String,
      default: "#fff"
    },
    urlParams: {
      type: String,
      default: "discoverTimeSerice"
    },
    // 纵坐标数据
    jldata: {
      type: Array,
      default: function(e) {
        return e;
      }
    },
    jltip: {
      type: String,
      defalut: ""
    }
  },

  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },

  watch: {
    jldata: function() {
      this.filterData();
    },
    jltip: function(newVal) {
      this.tips = newVal;
    }
  },
  mounted() {
    // this.chartData = this.jldata;
    // console.log("数据", this.chartData);
    this.tips = this.jltip;
    this.filterData();
  },
  methods: {
    async filterData() {
      let _this = this;
      _this.Xdata = [];
      _this.rightY = [];
      _this.firstData = [];
      _this.secondData = [];
      _this.thirdData = [];
      // return new Promise(function(resolve, reject) {
      //   _this.jldata.map(item => {
      //     _this.Xdata.push(item.timestamp);
      //     _this.rightY.push(item.close);
      //     _this.firstData.push(item.negativeArticle);
      //     _this.secondData.push(item.neutralArticle);
      //     _this.thirdData.push(item.positiveArticle);
      //   });
      //   resolve();
      // }).then(_this.renderChart());
      await _this.jldata.map(item => {
        _this.Xdata.push(item.timestamp);
        _this.rightY.push(item.close);
        _this.firstData.push(item.totalArticle);
        // _this.secondData.push(item.neutralArticle);
        // _this.thirdData.push(item.positiveArticle);
      });

      _this.Xdata = _this.Xdata.map((item, index) => {
        if (index % 2 !== 0) {
          item = "";
        } else {
          let temp = item.split("-");
          item = temp[1] + "-" + temp[2];
        }
        return item;
      });
      // console.log("back", _this.Xdata);
      // _this.Xdata = new Set([

      //   ..._this.Xdata,
      //   {
      //     value: _this.Xdata[_thix.Xdata.length - 1],
      //     textStyle: {
      //       fontSize: 20,
      //       color: "red"
      //     }
      //   }
      // ]);
      _this.renderChart();
    },
    renderChart: function() {
      let _this = this;
      _this.loading = false;

      if (this.chart) {
        this.chart.dispose();
      }
      // 初始化echart
      this.chart = echarts.init(document.getElementById(this.id));

      this.chart.setOption({
        title: {
          // text: _this.title,
          // left: "20",
          // top: "10",
          // textStyle: {
          //   color: "#b4b4b4",
          //   fontSize: "16"
          // }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
            // crossStyle: {
            //   color: "#999"
            // }
          },
          textStyle: {
            align: "left"
          },
          formatter: function(params) {
            var html = "";
            if (params.length != 0) {
              var getName = params[0].name;
              html += getName + "<br/>";
              for (let i = 0; i < params.length; i++) {
                // 如果为0 为空的数据我们不要了
                if (
                  params[i].value != null &&
                  params[i].value != 0 &&
                  params[i].value != ""
                ) {
                  // params[i].marker 需要加上，否则你鼠标悬浮时没有样式了
                  html += params[i].marker;
                  html +=
                    params[i].seriesName + ":" + params[i].value + "<br/>";
                } else {
                  html += params[i].marker;
                  html += params[i].seriesName + ":" + "暂无数据" + "<br/>";
                }
              }
            }
            return html;
          }
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          }
        },
        legend: {
          right: "30",
          top: "20",
          selectedMode: false,
          textStyle: {
            color: "#b4b4b4"
          },
          data: [
            { name: "舆情数量", icon: "diamond" },
            // { name: "中立舆情", icon: "diamond" },
            // { name: "消极舆情", icon: "diamond" },
            { name: "市场价格", icon: "roundRect" }
          ]
        },
        xAxis: [
          {
            type: "category",
            // data: [
            //   "1月",
            //   "2月",
            //   "3月",
            //   "4月",
            //   "5月",
            //   "6月",
            //   "7月",
            //   "8月",
            //   "9月",
            //   "10月",
            //   "11月",
            //   "12月"
            // ],

            // boundaryGap: false,
            data: _this.Xdata,
            axisPointer: {
              type: "shadow"
            },
            axisLine: {
              lineStyle: {
                color: "#b4b4b4"
                // color: "#fff"
              }
            },
            splitLine: {
              show: false //去掉网格线
            },
            axisLabel: {
              interval: 0,
              rotate: 40
            }
            // max: 8
          }
        ],
        yAxis: [
          {
            type: "value",
            // name: "舆情数",
            // min: 1,
            // max: 250,
            // interval: 50,
            minInterval: 1,
            splitLine: {
              show: false //去掉网格线
            },
            axisLabel: {
              formatter: "{value} "
            },
            axisLine: {
              // show: false
              lineStyle: {
                color: "#b4b4b4"
              }
            },
            scale: true
          },
          {
            type: "value",
            // name: "舆情数",
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: "{value} "
            },
            axisLine: {
              lineStyle: {
                color: "#b4b4b4"
              }
            },
            splitLine: {
              show: false //去掉网格线
            },
            scale: true
          }
        ],
        series: [
          {
            name: "市场价格",
            type: "line",
            areaStyle: {
              // color: ["rgba(250,250,250,0.5)", "rgba(200,200,200,0.05)"],
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "red" // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "blue" // 100% 处的颜色
                }
              ],
              global: true, //
              // shadowColor: "rgba(0, 0, 0, 0.05)",
              shadowBlur: 20,
              opacity: "0.5"
            },
            Interval: 1,
            symbol: "none",
            yAxisIndex: 1,
            lineStyle: {
              // width: 0
              color: "#B6B6B6"
            },
            itemStyle: {
              normal: { color: "grey" }
            },

            // data: [
            //   2.0,
            //   2.2,
            //   3.3,
            //   4.5,
            //   6.3,
            //   10.2,
            //   20.3,
            //   23.4,
            //   23.0,
            //   16.5,
            //   12.0,
            //   6.2
            // ]
            data: _this.rightY
          },
          {
            name: "舆情数量",
            type: "bar",
            stack: "舆情数",
            barWidth: 5,
            // data: [
            //   2.0,
            //   4.9,
            //   7.0,
            //   23.2,
            //   25.6,
            //   76.7,
            //   135.6,
            //   162.2,
            //   32.6,
            //   20.0,
            //   6.4,
            //   3.3
            // ],
            data: _this.firstData,
            itemStyle: {
              normal: { color: "#0097fe" },
              barBorderRadius: [10, 10, 5, 5]
            }
          }
          // {
          //   name: "消极舆情",
          //   type: "bar",
          //   stack: "舆情数",
          //   barWidth: 5,

          //   data: _this.secondData,
          //   itemStyle: {
          //     normal: { color: "#929292" },
          //     barBorderRadius: [5, 5, 0, 0]
          //   }
          // },
          // {
          //   name: "积极舆情",
          //   type: "bar",
          //   stack: "舆情数",
          //   barWidth: 5,

          //   data: _this.thirdData,
          //   itemStyle: {
          //     normal: { color: "#981A3C" },
          //     barBorderRadius: [5, 5, 0, 0]
          //   }
          // }
          // {
          //   name: "市场价格",
          //   type: "bar",
          //   stack: "舆情数",
          //   barWidth: 5,

          //   data: [],
          //   itemStyle: {
          //     normal: { color: "grey" }
          //   }
          // }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.stackbarbox {
  position: relative;
  width: 100%;
  // background: black;
  // background: #191919;
  @include themeBg1();
  display: flex;
  justify-content: center;
  align-items: center;

  .tipbox {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 6px;

    // color: #b4b4b4;
    z-index: 999;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    @include fontColor();

    .help {
      margin-left: 20px;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
}
</style>
