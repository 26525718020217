// 图表全局样式
export default {
  color: [
    "green",
    "yellow",
    "#41b883",
    "#1d8ce0",
    "#324057",
    "#2aab84",
    "#1568a6",
    "#2f4053"
  ],
  ringColor: ["#41b883", "#1d8ce0", "#324057", "#2aab84", "#1568a6", "#2f4053"]
};
