<template>
  <div
    class="gsap_progress"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="titlebox">
      <div class="title">{{ title }}</div>
      <div class="notice">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content">
            <p v-if="jltip == 1">
              “新闻热度“模块显示了同一时间区间内的新闻源数量与上一时期区间相比的百分比差异。
            </p>
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- d3 progress -->
    <div
      class="news-attention-container"
      :style="{ width: width + 'px', height: height + 'px' }"
      style="display: flex; overflow: hidden; flex-direction: column; height: 100%;"
    >
      <div style="flex: 1 1 auto;overflow: hidden; position: relative;">
        <div
          style="flex-direction: column; width: 100%;height: 100%;flex-direction: column; display: flex; align-items: center; justify-content: center;"
        >
          <svg id="tcl-gauge-svg svgRef" class="svgRef">
            <defs>
              <!--      id='"svgGradient1-"{{ uniqueId }}' -->
              <linearGradient
                :id="'#svgGradient1-' + uniqueId"
                x1="0%"
                x2="0%"
                y1="0%"
                y2="100%"
              >
                <stop
                  class="start"
                  offset="20%"
                  stop-opacity="1"
                  :attr-stopcolor="color1Offset"
                ></stop>
                <stop
                  class="end"
                  offset="100%"
                  stop-opacity="1"
                  :attr-stopcolor="color2Offset"
                ></stop>
              </linearGradient>
            </defs>
          </svg>
          <div style="width: 100%; text-align: center;">
            <span
              id="textLabel"
              :style="{ color: labelColor, 'font-size': textSize }"
            ></span>
          </div>
        </div>
      </div>

      <div class="tcl-bottom-container">
        <div class="pubNumber" :style="{ 'font-size': textSize }">
          {{ pubLabel }}
          <span id="pubNumber">
            <!-- {{ pubNumber }}条 -->
            {{ jldata.all_count }}条
          </span>
        </div>
        <span style="flex:  1 1 auto;"></span>

        <div
          class="delta"
          :style="{ 'font-size': textSize, color: percentageColor }"
        >
          <p id="percentage">
            <!-- {{ percentage }}% -->

            {{ comment }}
            <!-- <span
              class="percent"
              :class="{
                up: jldata.articleCountRatio > 0,
                blow: jldata.articleCountRatio < 0
              }"
              >{{ jldata.articleCountRatio }}%</span
            > -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import {
  gsap,
  MorphSVGPlugin,
  Linear,
  Elastic,
  Power1,
  TweenLite,
  TextPlugin
} from "gsap/all";
import tools from "@/utils/tools";
export default {
  name: "gsap_progress",
  data() {
    return {
      loading: true,
      colorScale: null,

      angleRangeMin: 0,
      angleRangemax: 100,

      angleScaleDeg: d3
        .scaleLinear()
        .range([this.angleRangeMin, this.angleRangemax])
        .domain([0, 100]),

      uniqueId: null,
      radiusSmall: 32,
      radiusBig: 39,
      needleScale: 0.35,
      previousAngle: 0,
      isIE:
        false ||
        !!window.document["documentMode"] ||
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      textSize: "1rem",
      arcsData: null,
      svgRef: d3.select(".svgRef"),
      hideRect: false,
      textLabel: d3.select("#textLabel"),
      textX: 50,
      iconRef: null,
      rectObject: {
        x: 0,
        y: 0,
        height: 14,
        width: 0
      },
      iconObject: {
        x: 0,
        y: 0,
        height: 14,
        width: 0
      },
      viewBoxX: 100,
      viewBoxY: 55,
      arcColorGenerator: null,
      arcBgGenerator: null,
      arcScale: d3
        .scaleLinear()
        .range([0.05, Math.PI - 0.05])
        .domain([0, 100]),
      pubNumberSpan: d3.select("#pubNumber"),
      percentageSpan: d3.select("#percentage"),
      pubNumber: 0,
      pubLabel: "舆情量：",
      percentage: 48,
      percentageColor: "#ff7373",
      spacingSize: "1rem",
      needleColor: "#b4b4b4", //指针颜色
      arcBgColor:
        window.document.documentElement.getAttribute("data-theme") == "dark"
          ? ["blue", "orange", "yellow"]
          : ["blue", "orange", "yellow"],

      color1Offset: "red",

      color2Offset: "red",

      color3Offset: "green",

      color4Offset: "green",

      currentAngle: "0",

      label: "label",

      labelColor: "blue",
      piSlices: "3",
      piPartition: "3",

      isBuzzing: false,
      currParams: null,
      comment: ""
    };
  },
  mixins: [tools],
  props: {
    label1: {
      type: String,
      default: "舆情数"
    },
    label2: {
      type: String,
      default: "市场价格"
    },
    width: {
      type: Number,
      default: 300
    },

    height: {
      type: Number,
      default: 300
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    title: {
      type: String,
      default: "新闻热度"
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  watch: {
    jldata: function(newVal) {
      console.log("watch", newVal);
      this.filterData(newVal);
    }
  },
  created() {},
  mounted() {
    this.loading = false;
    this.uniqueId = Math.floor(Math.random() * Math.floor(4000));
    gsap.registerPlugin(TextPlugin);
    // this.initGradientColors();
    this.filterData(this.jldata);
    // this.tweenTexts(this.textLabel, this.label);
  },
  methods: {
    filterData(e) {
      // console.log("值", Math.abs(e.articleCountRatio));
      // let params = Math.abs(e.articleCountRatio);
      this.currParams = 0;
      this.currentAngle = 0;
      this.pubNumber = e.all_count;
      if (e.buzzLevel == "noNews") {
        this.currParams = 0;
        this.currentAngle = 0;
        this.comment = "无新闻";
      } else if (e.buzzLevel == "belowAverage") {
        this.currParams = 25;
        this.currentAngle = 25;
        this.comment = "低于均值";
      } else if (e.buzzLevel == "Average") {
        this.currParams = 50;
        this.currentAngle = 50;
        this.comment = "平均值";
      } else if (e.buzzLevel == "aboveAverage") {
        this.currParams = 75;
        this.currentAngle = 75;
        this.comment = "高于均值";
      } else if (e.buzzLevel == "buzzing") {
        this.currParams = 100;
        this.currentAngle = 100;
        this.comment = "热度极高";
      } else {
        this.comment = "比例";
      }
      this.initChart();
      this.animatevalues();
    },
    animatevalues() {
      const duration = 2000;
      setTimeout(() => {
        d3.select("#pubNumber")
          /*  .style('fill', 'url('+window.location.href+'#mainGradient)') */
          .transition()
          .duration(duration)
          .tween("innerText", (d, i, nodes) =>
            this.tweenTextNumber(d, i, nodes, this.pubNumber)
          );

        if (!isFinite(this.percentage)) {
          this.percentageSpan.interrupt();
          this.percentageSpan.html("&infin;");
        } else {
          this.percentageSpan
            .transition()
            .duration(duration)
            .tween("innerText", (d, i, nodes) =>
              this.tweenTextNumberDelta(d, i, nodes, this.percentage)
            );
        }
      }, 100);
    },
    tweenTextNumberDelta(d, i, nodes, number) {
      var format = d3.format(".0f");
      var int = d3.interpolate(0, number);
      return function(t) {
        var itp = int(t);
        if (itp != "Infinity") {
          if (itp > 0) {
            nodes[i].innerText = "+" + format(int(t)) + "%";
          } else {
            nodes[i].innerText = format(int(t)) + "%";
          }
        }
      };
    },

    tweenTextNumber(d, i, nodes, number) {
      var format = d3.format(".0f");

      //keep a reference to 'this'
      var int = d3.interpolate(0, number);
      return function(t) {
        nodes[i].textContent = format(int(t));
        //use that reference in the inner function
      };
    },
    ngOnChanges(changes) {
      if (changes.label && this.textLabel) {
        this.tweenTexts(this.textLabel, this.label);
      }

      if (changes.currentAngle) {
        this.moveArc();
      }

      if (changes.color1Offset || changes.color2Offset) {
        this.initGradientColors();
      }
    },
    translateCenter() {
      return (
        "translate(" +
        this.viewBoxX / 2 +
        "," +
        (this.viewBoxY / 2 + this.radiusBig / 2) +
        ")"
      );
    },
    async initChart() {
      await this.initGradientColors();
      await this.initArcFactories();
      d3.select(".svgRef").html(null);
      const svgEl = d3
        .select(".svgRef")
        .attr("viewBox", "0 0 " + this.viewBoxX + " " + this.viewBoxY);

      let el = {
        startAngle: 0.05,
        endAngle: 0.1,
        currentAngle: 0.01,
        radiusSmall: this.radiusSmall,
        radiusBig: this.radiusBig
      };

      const arcBox = svgEl
        .append("g")
        .attr("transform", this.translateCenter() + " rotate(-90)");

      arcBox
        .append("path")
        .attr("d", this.arcBgGenerator(el))
        .style("fill", this.arcBgColor)
        .style("opacity", "1");

      arcBox
        .append("path")
        .datum(el)
        .attr("id", "mainArc-" + this.uniqueId)
        .attr("d", this.arcColorGenerator(el))
        // .style("fill", "url(#svgGradient1-" + this.uniqueId + ")")
        .style("fill", "#e99863") //半圆环颜色
        .style("opacity", "1");

      let needlePath =
        "M0.9-7.1c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7c3.9,0,59.5-5.8,59.5-6.8C60.4-0.9,4.8-7.1,0.9-7.1z M2.9,1.3C2.4,2.9,0.6,3.7-0.9,3.2c-1.6-0.6-2.4-2.3-1.8-3.8c0.6-1.6,2.3-2.4,3.8-1.8C2.7-1.9,3.5-0.2,2.9,1.3z";
      let needleTranslate;
      if (this.isIE) {
        needleTranslate =
          "translate(50," + (this.viewBoxY / 2 + this.radiusBig / 2 - 3) + ")";
      } else {
        needleTranslate =
          "translate(50," + (this.viewBoxY / 2 + this.radiusBig / 2 - 3) + ")";
      }
      const pointerEnter = svgEl
        .append("g")
        .attr("class", "pointer")
        .attr(
          "transform",
          needleTranslate + " scale(" + this.needleScale + ") rotate(180)"
        )
        .append("path")
        .attr("id", "pointer_needle-" + this.uniqueId)
        .style("fill", this.needleColor)
        .attr("d", needlePath);

      setTimeout(() => {
        this.moveArc();
      }, 200);
    },
    moveArc() {
      if (!d3.select("#mainArc-" + this.uniqueId).empty()) {
        d3.select("#mainArc-" + this.uniqueId)
          .transition()
          .duration(500)
          .attrTween("d", this.arcTween());
      }

      d3.select("#pointer_needle-" + this.uniqueId).attr(
        "transform",
        // "rotate(" + this.angleScaleDeg(this.currentAngle) + ")"
        // "rotate(" + this.currParams * 1.8 + ")"
        "rotate(" + this.currentAngle * 1.8 + ")" //指针转动角度 百分比最大是100 。圆环角度是180度
      );
    },
    arcTween() {
      return d => {
        const interpolate = d3.interpolate(
          d["currentAngle"],
          this.arcScale(this.currentAngle)
        );

        return t => {
          d["currentAngle"] = interpolate(t);
          var arc = this.arcColorGenerator(d);
          // console.log("resturn", arc);
          return arc;
        };
      };
    },
    initArcFactories() {
      this.arcColorGenerator = d3
        .arc()
        .innerRadius(d => this.radiusSmall)
        .outerRadius(d => this.radiusBig)
        .startAngle(d => 0.05)
        .endAngle(d => {
          return d["currentAngle"] - 0.01;
        })
        .cornerRadius(50);

      this.arcBgGenerator = d3
        .arc()
        .innerRadius(d => this.radiusSmall - 1.5)
        .outerRadius(d => this.radiusBig + 1.5)
        .startAngle(d => 0)
        .endAngle(d => Math.PI)
        .cornerRadius(50);
    },
    initGradientColors() {
      d3.select("#svgGradient1-" + this.uniqueId)
        .select(".start")
        .attr("stop-color", this.color1Offset);
      d3.select("#svgGradient1-" + this.uniqueId)
        .select(".end")
        .attr("stop-color", this.color2Offset);
    },

    tweenTexts(ref, val) {
      console.log("val", val);
      TweenLite.to(ref, 1, {
        text: { value: val, delimiter: "" },
        ease: Linear.easeNone
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.gsap_progress {
  // width: 400px;
  // height: 300px;
  // background: #191919;
  @include themeBg1();
  position: relative;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      color: #b4b4b4;
      margin-left: 20px;
      z-index: 999;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }

  .news-attention-container .tcl-bottom-container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    .pubNumber {
      // color: #fff;
      @include fontColor2();

      #pubNumber {
        color: #33a8ee;
      }
    }
  }
  .news-attention-container svg {
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  .news-attention-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  path#arcSpeedo {
    transition: transform 700ms ease;
    -o-transition: transform 700ms ease;
  }

  #tcl-gauge-svg .pointer path {
    transition: transform 200ms ease;
    -o-transition: transform 200ms ease;
  }

  #tcl-gauge-svg path.selectableArc {
    transition: opacity 0.3s;
    opacity: 0.4;
  }
  svg#tcl-gauge-svg {
    height: auto;
    width: 100%;
    flex: 0 1 auto;
    margin-top: 10px;
  }
  #tcl-gauge-svg g {
    transition: transform 0s;
  }
  #tcl-gauge-svg path.iconRef {
    opacity: 0;
    transition: opacity 200ms;
  }
  #tcl-gauge-svg path.selected,
  #tcl-gauge-svg path.iconRef.show {
    opacity: 1;
  }

  #tcl-gauge-svg rect {
    transition: fill 200ms;
  }

  #tcl-gauge-svg text.labelRef {
    transition: transform 200ms;
    text-anchor: middle;
    dominant-baseline: middle;
  }

  #tcl-gauge-svg path.hidden {
    display: none;
  }

  #tcl-gauge-svg path.show {
    display: initial;
  }

  #tcl-gauge-svg .pointer path {
    transition: transform 200ms ease;
    -o-transition: transform 200ms ease;
  }
}
</style>
