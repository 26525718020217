export const base = {
  A: "cn",
  SECTOR: "cn/sector",
  CONCEPT: "cn/concept",
  HK: "hk",
  US: "us",
  FOREX: "forex",
  INDICES: "indices",
  COMMODITIES: "commodities"
};
