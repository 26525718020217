import qs from "qs";

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
// 获取iframe链接传递过来的参数
function getUrl(val) {
  let idName = window.location.href;
  let params = idName.split("?")[1];
  let tempObj = {};
  try {
    let tempArr = params.split("&");

    tempArr.map(item => {
      let Arr = item.split("=");
      tempObj[Arr[0]] = Arr[1];
    });
  } catch (err) {
    return "";
  }
  return tempObj[val] || "";
  // return qs.parse(params)[val] || "";
}
function getCookie(cookie_name) {
  var allcookies = document.cookie;
  //索引长度，开始索引的位置
  var cookie_pos = allcookies.indexOf(cookie_name);

  // 如果找到了索引，就代表cookie存在,否则不存在
  if (cookie_pos != -1) {
    // 把cookie_pos放在值的开始，只要给值加1即可
    //计算取cookie值得开始索引，加的1为“=”
    cookie_pos = cookie_pos + cookie_name.length + 1;
    //计算取cookie值得结束索引
    var cookie_end = allcookies.indexOf(";", cookie_pos);

    if (cookie_end == -1) {
      cookie_end = allcookies.length;
    }
    //得到想要的cookie的值
    var value = unescape(allcookies.substring(cookie_pos, cookie_end));
  }
  return value;
}
export { getQueryString, getCookie, getUrl };
