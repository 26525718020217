<template>
  <div
    class="dynamicnews"
    :style="{ width: width - 10 + 'px', height: '700px' }"
  >
    <div style="color:#fff;line-height:60px;height:60px;">
      根据国家数据安全管理办法及相关版权规定, 部分新闻无法显示。
    </div>
    <ul class="newsbox" v-if="dynamicData.length > 0">
      <li
        v-for="(item, index) in dynamicData"
        :key="item"
        :index="index"
        @click="openDrawer(item)"
      >
        <div class="left">
          <div class="avr">
            <img :src="item.logoUrl" alt="" />
          </div>
        </div>
        <div class="right">
          <div class="time">
            <p class="de">{{ item.date_time }}</p>
            <p class="from">{{ item.provider }}</p>
          </div>
          <div class="content">
            {{ item.title }}
          </div>
        </div>
      </li>
    </ul>

    <el-drawer
      :custom-class="'newTips'"
      :visible.sync="drawer"
      :before-close="handleClose"
      v-if="dynamicData.length > 0"
    >
      <div style="max-height:80%;overflow:auto;">
        <p style="">
          {{ currItem.content }}
        </p>
        <p>{{ currItem.date_time }}</p>
        <p>{{ currItem.provider }}</p>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import tools from "@/utils/tools";

export default {
  name: "dynamicnews",
  data() {
    return {
      loading: true,
      dynamicData: [],
      drawer: false,
      currItem: null
    };
  },
  mixins: [tools],
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 200
    },
    title: {
      type: String,
      default: "综合行为评测"
    },
    jldata: {
      type: Array,
      default: function() {
        return [];
      }
    },
    newOrgin: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  watch: {
    jldata: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.dynamicData = newVal;
          this.currItem = this.dynamicData[0];
        } else {
          this.dynamicData = [];
        }
      }
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  mounted() {
    this.dynamicData = this.jldata || [];
  },
  methods: {
    handleClose(done) {
      done();
      this.drawer = false;
    },
    openDrawer(e) {
      this.drawer = true;
      this.currItem = e;
    }
  }
};
</script>
<style lang="scss">
.dynamicnews {
  overflow: auto;
  width: 100%;
  // background: #191919;
  @include themeBg1();

  .newsbox {
    li {
      display: flex;
      align-items: center;
      // min-height: 100px;
      padding-top: 20px;
      cursor: pointer;
      .left {
        width: 100px;
        text-align: center;
        .avr {
          border-radius: 50%;
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          // background: #b4b4b4;
          overflow: hidden;
          img {
            width: 60px;
            // height: 40px;
            // border-radius: 50%;
          }
        }
      }
      .right {
        text-align: left;
        padding-right: 20px;
        flex: 1;
        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .de {
            // color: #fdca64;
            @include news_fontColor2();
            font-size: 16px;
          }
          .from {
            // color: #b4b4b4;
            @include fontColor();

            text-align: right;
            font-size: 16px;
          }
        }
        .content {
          // color: #b4b4b4;
          @include fontColor();
          font-size: 18px;
        }
      }
    }
    li:hover {
      cursor: pointer;
    }
  }
  .newTips {
    // background: #222;
    // color: #fff;
    padding: 0 20px;
    text-indent: 40px;
    z-index: 10000;
    text-align: left;
    @include fontColor();
    @include themeBg1();
    .el-drawer__body {
      height: 100%;
    }
    .el-drawer__close-btn {
      background: #a3a3a3;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      // color: #fff;
      @include fontColor();
    }
    .el-drawer__body {
      text-align: left;
    }
  }
}
.dynamicnews::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.dynamicnews::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
  background: #535353;
}
.dynamicnews::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
