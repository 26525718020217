import { getInit, getNewsOrigin } from "@/request/config";

export default {
  data() {
    return {
      bigTab: "articlecount/v1/",
      companyParams: "discover/v1/",
      moodParams: "timeseries/",
      industryParams: "articlecount/v1/sectors",
      industryLabel: "articlecount/v1/sectors",
      newsParams: "newsfeed/v1/all",
      sameParams: "sectorview/",
      baseInfo: {},
      companyInfo: {}
    };
  },
  methods: {
    // 获取唯一id
    getID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    getOtherID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(24);
    },
    getCompanyData(data) {
      console.log(111);
      let param = `${this.companyParams}${data.entity_id}`;
      let companyInfo = null;
      this.getBarData(data);
      this.getSameData(data);

      getInit(param).then(res => {
        companyInfo = res.data.entity;
        console.log("return", companyInfo);
        this.baseInfo.companyInfo = res.data.entity;
        return companyInfo;
      });
    },
    getNews() {
      this.loading = true;
      let _this = this;
      console.log(this.companyInfo);
      let param = `${this.newsParams}/${this.baseInfo.companyInfo.entityId}`;
      // let param = `${this.newsParams}`;
      _this.baseInfo.newsArr = [];
      getInit(param).then(res => {
        this.loading = false;
        _this.baseInfo.newsArr = res.data.newsFeed;
        _this.newsArray.map(i => {
          _this.newsArr.map(j => {
            if (i.provider == j.provider) {
              j.logoUrl = i.logoUrl;
            }
          });
        });
      });
    },
    getSameData(data) {
      this.loading = false;

      let param = `${this.companyParams}${this.sameParams}${data.entity_id}`;

      getInit(param).then(res => {
        let sameInfo = res.data.sectorView;
        this.baseInfo.sameInfo = sameInfo;
      });
    },
    getBarData(data) {
      let param = `${this.companyParams}${this.moodParams}${data.entity_id}/30`;
      getInit(param).then(res => {
        // let barData = res.data.newsAndSentimentHistory;
        this.baseInfo.barData = res.data.newsAndSentimentHistory;
      });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");

      var r = window.location.search.substr(1).match(reg);

      if (r != null) return unescape(r[2]);

      return null;
    },
    handleCheckAllChange(val) {
      console.log("val", val);
      // this.checkedNews = val ? this.newsArray : [];
      // this.isIndeterminate = false;
      val ? (this.checkedNews = [0, 1]) : (this.checkedNews = []);
    },
    handleCheckedNewsChange(value) {
      console.log(this.checkedNews);
    }
  }
};
