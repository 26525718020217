<template>
  <div
    class="page"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      class="jlsubjective"
      :style="{ width: width + 'px', height: height + 'px' }"
    >
      <div class="titlebox">
        <div class="title">{{ title }}</div>
        <div class="notice">
          <el-tooltip placement="bottom" effect="light">
            <div slot="content">
              <p v-if="jltip == 1">
                主观性指数衡量的是大众对一个实体或资产看法的理性程度，这有助于识别市场泡沫。它的分数范围从
                0（非常理性）到 100（非常不理性）。
              </p>
            </div>
            <el-button @click="toggleTips()"> ? </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="imgbox" :style="{ width: width + 'px' }">
        <img
          v-if="currmood == 2"
          src="./../../public/img/chart/objective.png"
          alt=""
        />
        <img
          v-if="currmood == 1"
          src="./../../public/img/chart/subjective.png"
          alt=""
        />
      </div>
      <div class="numbox">
        <span class="mood">{{ comment }}</span>
        <span>({{ Math.floor(data1 * 100) }}/100)</span>
      </div>
      <div class="switchbtn">
        <div class="all" @click="changeTab('all')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="notall"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqdiqiuquanqiu"
              :class="{ activeicon: active == 'all' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
        <div class="zh" @click="changeTab('cs')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="notcs"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqzhongwen"
              :class="{ activeicon: active == 'cs' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
        <div class="en" @click="changeTab('en')">
          <el-popover
            placement="bottom"
            title=""
            width="100"
            trigger="manual"
            v-model="noten"
            content="该资产当前无此类文章"
          >
            <span
              slot="reference"
              class="iconfontyuqing icon-yqyingwen"
              :class="{ activeicon: active == 'en' }"
              style="color:#bababa;"
            ></span>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tools from "@/utils/tools";

export default {
  name: "newsubjective",
  data() {
    return {
      loading: true,
      currmood: 0,
      subjectiveData: {},
      tips: "",
      callData: {},
      data1: 0,
      data2: 0,
      comment: null,
      showCircle: true,
      active: "all",
      notall: false,
      notcs: false,
      noten: false
    };
  },

  mixins: [tools],
  components: {},
  props: {
    // jldata: {
    //   type: Array
    // },
    width: {
      type: String,
      default: "300"
    },
    height: {
      type: Number,
      default: 260
    },
    title: {
      type: String,
      default: "情绪指向"
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    jltip: {
      type: String,
      defalut: ""
    }
  },
  watch: {
    jldata: function(newVal) {
      this.subjectiveData = newVal;
      this.notall = false;
      this.notcs = false;
      this.noten = false;
      newVal.sentiments.forEach(item => {
        if (item.language == "all") {
          this.callData = item;
          this.active = "all";
          this.data1 = item.total.subjectivity;
          this.filterData(item.total.subjectivity);
        }
      });
    },
    jltip: function(newVal) {
      this.tips = newVal;
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  mounted() {
    this.loading = false;
    this.active = "all";
    this.notall = false;
    this.notcs = false;
    this.noten = false;

    this.subjectiveData = this.jldata;
    this.tips = this.jltip;
    this.changeTab("all");
  },
  methods: {
    numsLang(e) {
      if (e == "all") {
        return "合计 ";
      } else if (e == "en") {
        return "英文";
      } else if (e == "zh-hans") {
        return "中文";
      } else if (e == "fr") {
        return "法语";
      }
    },
    changeTab(params) {
      let tempData = this.jldata.sentiments;
      let nodata = true;
      this.noten = false;
      this.notcs = false;
      this.notall = false;
      tempData.forEach(item => {
        if (item.language == params) {
          this.callData = item;
          this.active = params;
          nodata = false;
          this.data1 = item.total.subjectivity;

          this.filterData(item.total.subjectivity);
        }
      });
      if (nodata) {
        if (params == "all") {
          this.notall = true;
          setTimeout(() => {
            this.notall = true;
          }, 3000);
        }
        if (params == "cs") {
          this.notcs = true;
          setTimeout(() => {
            this.notcs = false;
          }, 3000);
        }
        if (params == "en") {
          this.noten = true;
          setTimeout(() => {
            this.noten = false;
          }, 3000);
        }
      }
    },
    filteClass(e) {
      if (-1 < e && e <= -0.5) {
        return "color5";
      } else if (-0.5 < e && e <= -0.1) {
        return "color4";
      } else if (-0.1 < e && e <= 0.1) {
        return "color3";
      } else if (0.1 < e && e <= 0.5) {
        return "color2";
      } else if (0.5 < e && e <= 1) {
        return "color1";
      }
    },
    filterData(e) {
      if (e <= 0.5) {
        this.comment = "低主观";
        this.currmood = 2;
      } else if (e > 0.5) {
        this.currmood = 1;
        this.comment = "高主观";
      }
    }
  }
};
</script>
<style lang="scss">
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themeBg1();
}
.jlsubjective {
  width: 100%;
  @include themeBg1();
  .titlebox {
    display: flex;
    height: 40px;
    align-items: center;
    margin-left: 10px;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      @include fontColor();

      margin-left: 20px;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .imgbox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 176px;
      height: 176px;
      margin: 0 auto;
    }
  }
  .numbox {
    height: 30px;
    line-height: 30px;
    color: #fff;
  }
  .switchbtn {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .all {
      margin-left: 20px;
      cursor: pointer;
    }
    .zh {
      margin-left: 40px;
      cursor: pointer;
    }
    .en {
      margin-left: 40px;
      cursor: pointer;
    }
    .activeicon {
      color: #fff !important;
    }
  }
}
</style>
