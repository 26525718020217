import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

/**
 * gsap
 */
import { gsap } from "gsap";
Vue.use(gsap);
/**
 * echart
 */
import echarts from "echarts";
// Vue.use(echarts);
Vue.prototype.$echarts = echarts;
/**
 * cookie function plugin
 */
let VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
Vue.use(VueCookie);
Vue.config.productionTip = false;

import './theme.scss'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
