<template>
  <div
    class="jlrank"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="titlebox">
      <div class="title">{{ title }}</div>
      <div class="notice">
        <el-tooltip placement="bottom" effect="light">
          <div slot="content">
            <p v-if="jltip == 1">
              “最热门讨论话题”显示新闻、社交媒体对该实体讨论最多的事件和话题。产品AI技术可识别超过300种金融相关事件。
            </p>
          </div>
          <el-button @click="toggleTips()"> ? </el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="rank">
      <ul v-if="rankData.length > 0">
        <li v-for="(item, index) in rankData" :key="item" :index="index">
          <div class="title">
            <p class="point">
              <span></span>
            </p>
            <p class="content">{{ item.name }}</p>
          </div>
          <div class="progress">
            <div class="point"></div>
            <div class="content">
              <el-progress
                :percentage="item.percent"
                :color="item.color"
                :format="formatprogress"
              ></el-progress>
            </div>
          </div>
        </li>
      </ul>
      <div v-else style="height:180px;color:#fff;">暂无数据</div>
    </div>
  </div>
</template>
<script>
import tools from "@/utils/tools";

export default {
  name: "jlrank",
  data() {
    return {
      loading: true,
      rankData: []
    };
  },
  mixins: [tools],
  props: {
    // jldata: {
    //   type: Array
    // },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 200
    },
    title: {
      type: String,
      default: "排名"
    },
    jldata: {
      type: Object,
      default: function(e) {
        return e;
      }
    },
    jltip: {
      type: Number,
      defalut: 0
    }
  },
  watch: {
    jldata: function(newVal) {
      this.rankData = this.filterData(newVal.topics);
      // this.rankData = newVal.topics;
    }
  },
  computed: {
    // 生成一个随机id, 实现图表组件的复用
    id: function() {
      // return parseInt(Math.random() * 1000000);
      return this.getID();
    }
  },
  mounted() {
    this.loading = false;
    this.rankData = this.filterData(this.jldata.topics);
    // this.rankData = this.jldata.topics;
  },
  methods: {
    filterData(e) {
      let tempData = e;

      for (let i = 0; i < tempData.length; i++) {
        if (i == 0) {
          tempData[i].color = "#FF7373";
        } else {
          tempData[i].color = "#E99863";
        }
      }
      // console.log(tempData);
      return tempData;
    },
    formatprogress(percentage) {
      // return percentage === 100 ? "满" : `${percentage}%`;
      // for(let i in this.rankData)
      let _this = this;

      for (let i = 0; i < _this.rankData.length; i++) {
        if (_this.rankData[i].percent == percentage) {
          return `${_this.rankData[i].value}`;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.jlrank {
  width: 100%;
  // background: black;
  // background: #191919;
  @include themeBg1();

  .titlebox {
    display: flex;
    align-items: center;
    .title {
      margin-left: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .rank {
    padding: 0 10px;
    ul {
      height: 180px;
      // padding: 0 10px;
      overflow: auto;
      li {
        // height: 20px;
        .title {
          display: flex;
          align-items: center;
          .point {
            width: 20px;
            span {
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              // background: #b4b4b4;
              @include fontColor2();
            }
          }
          .content {
            // color: #fff;
            @include fontColor2();

            font-size: 14px;
          }
        }
        .progress {
          display: flex;
          height: 14px;
          // align-items: center;
          // .point {
          //   width: 20px;
          //   height: 5px;
          //   background: #fff;
          // }
          .content {
            width: 90%;
            .el-progress-bar__outer {
              background-color: transparent !important;
            }
            .el-progress__text {
              // color: #fff;
              @include fontColor2();
            }
          }
        }
      }
    }
  }
}
</style>
